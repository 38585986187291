
import { leadHousingPlanConstants} from './leadHousingPlan.Constants';

export function changeAbboDurage(durage) { 
  return dispatch => { 
    dispatch(change(durage));
    function change(data) { return { type: leadHousingPlanConstants.HOUSING_PLAN_ABBODURAGE, data } }
  }
}

export function changeAbboStartYear(startYear) {
  return dispatch => {
    dispatch(change(startYear));
    function change(data) { return { type: leadHousingPlanConstants.HOUSING_PLAN_STARTYEAR, data } }
  }
}

// Voordat de gehele bestek gevuld wordt. wordt hier de lijst geinitialiseerd. 
//Hierbij wordt gekeken naar de jaren, daarna de gevels en dan de elementen in een gevel.
export function initialHousingPlanData(startyear, totalYears, selectedHousingFaces, products, currentHousingPlanData) {
  var productSortList = getStaticProductSort(products);
  var housingPlanData = [];
  var year = startyear;
  var productYearCyclus = 0;

  // Als eerste, jaren. Op overzicht kan je per jaar gebruiken. 
  for (var i = 0; i < totalYears; i++) {
    var currentYearData;
    if (currentHousingPlanData) {
      currentYearData = currentHousingPlanData[i];
      if (currentYearData) {
        currentYearData.year = startyear + i;
      }
    }

    if (currentYearData) {
      housingPlanData.push(currentYearData);
    }
    else {
      var currentProduct = productSortList[productYearCyclus];

      if (currentProduct != null) {
        var factor = currentProduct.factor;
        var minAmount = currentProduct.minAmount;

        var yearData = {
          year: year,
          yearTotalPrice: 0,
          correctionPrice: '',
          yearTotalPriceWithCorrection: 0,
          selectedProduct: currentProduct.abbreviation,
          selectedProductFactor: currentProduct.factor,
          selectedProductMinAmount: currentProduct.minAmount,
          selectedProductDescription: currentProduct.name,
          housingFaceData: []
        }

        var totalYearPrice = 0;

        // Daarna de gevels. 
        for (var j = 0; j < selectedHousingFaces.length; j++) {
          var housingFace = selectedHousingFaces[j];

          var housingFaceData = {
            housingFaceId: housingFace.housingFace.housingFaceId,
            housingFaceTemplateId: housingFace.housingFace.housingFaceTemplateId,
            housingFaceName: housingFace.housingFace.name,
            housingFaceElements: []
          }

          var housingFaceTotalPrice = 0;

          // Daarna de elementen bij een gevel. 
          for (var k = 0; k < housingFace.elements.length; k++) {
            var element = housingFace.elements[k];

            var elementTotalPrice = 0;
            for (var l = 0; l < element.elementImplementations.length; l++) {
              var price = element.elementImplementations[l].price * element.elementImplementations[l].count
              elementTotalPrice += price;
            }

            housingFaceTotalPrice += elementTotalPrice;
            var factorElementPrice = Math.round(elementTotalPrice * factor);

            var housingElement = {
              housingElementId: element.housingElementId,
              housingElementName: element.housingElementName,
              housingElementTemplateId: element.housingElementTemplateId,
              housingElementTotalPrice: elementTotalPrice,
              housingElementFactorPrice: factorElementPrice,
              housingElementSelectedProduct: currentProduct.abbreviation,
              housingElementSelectedProductFactor: currentProduct.factor,
              housingElementSelectedProductMinAmount: currentProduct.minAmount,
              housingElementProductDescription: currentProduct.name
            }
            housingFaceData.housingFaceElements.push(housingElement);
          }
          yearData.housingFaceData.push(housingFaceData);

          totalYearPrice += housingFaceTotalPrice;
        }

        yearData.yearTotalPrice = Math.max(totalYearPrice, minAmount);

        housingPlanData.push(yearData);
      }
    }

    if (productYearCyclus === productSortList.length - 1) {
      productYearCyclus = -1;
    }
    ++productYearCyclus;

    ++year;
  }

  return housingPlanData;
}

// Wanneer in overzicht een product bij een bepaald jaar wordt gewijzigd.
export function changingProductsByHouseYear(yearPlanData, product) {
  if (yearPlanData[0] != null && product != null) {
    yearPlanData[0].selectedProduct = product.abbreviation;
    yearPlanData[0].selectedProductDescription = product.name;
    yearPlanData[0].selectedProductFactor = product.factor;
    yearPlanData[0].selectedProductMinAmount = product.minAmount;

    for (var i = 0; i < yearPlanData[0].housingFaceData.length; i++) {
      var housingFace = yearPlanData[0].housingFaceData[i];

      for (var j = 0; j < housingFace.housingFaceElements.length; j++) {
        var faceElement = housingFace.housingFaceElements[j];
        faceElement.housingElementSelectedProduct = product.abbreviation;
        faceElement.housingElementProductDescription = product.name;
        faceElement.housingElementSelectedProductFactor = product.factor;
        faceElement.housingElementSelectedProductMinAmount = product.minAmount;
      }
    }
  }
}

// Calculatie proces. 
export function calculatePrices(housingData) {
  var hardMinimum = 75; // Prices may never be less than this number!!!
  for (var i = 0; i < housingData.length; i++) {
    var yearTotalPrice = 0;
    var minAmount = 0;

    for (var j = 0; j < housingData[i].housingFaceData.length; j++) {
      var housingFace = housingData[i].housingFaceData[j];

      for (var k = 0; k < housingFace.housingFaceElements.length; k++) {
        var faceElement = housingFace.housingFaceElements[k];
        var factor = faceElement.housingElementSelectedProductFactor;
        minAmount = Math.max(faceElement.housingElementSelectedProductMinAmount);

        var newFactorPrice = Math.round(faceElement.housingElementTotalPrice * factor);
        faceElement.housingElementFactorPrice = newFactorPrice;

        yearTotalPrice += newFactorPrice;
      }
    }

    housingData[i].yearTotalPrice = Math.max(yearTotalPrice, minAmount);
    if (housingData[i].yearTotalPrice < hardMinimum) {
      housingData[i].yearTotalPrice = hardMinimum;
    }
    housingData[i].yearTotalPriceWithCorrection = housingData[i].yearTotalPrice + (housingData[i].correctionPrice === '' ? 0 : Number(housingData[i].correctionPrice));
    if (housingData[i].yearTotalPriceWithCorrection < hardMinimum) {
      housingData[i].yearTotalPriceWithCorrection = hardMinimum;
    }
  }
}

// Dit is een default lijstje die wordt gebruikt bij het initialiseren van de lijst. 
export function getStaticProductSort(products) {
  var productSort = [];

  if (products.length > 0) {
    var HER = products.filter((e) => e.abbreviation === "HER");
    var INSP = products.filter((e) => e.abbreviation === "INSP");
    var OHB = products.filter((e) => e.abbreviation === "OHB");

    productSort.push(HER[0]);
    productSort.push(INSP[0]);
    productSort.push(INSP[0]);
    productSort.push(OHB[0]);
    productSort.push(INSP[0]);
    productSort.push(INSP[0]);
  }

  return productSort;
}
