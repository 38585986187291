import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TitleComponent, HeaderComponent } from 'components';
import { Link } from 'react-router-dom'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import './customerAdd.Style.css';

class CustomerAddComponent extends Component {

  render() {
    return <div className="page-wrapper">
      <div className="page-header">
        <HeaderComponent />
      </div>
      <div className="page-content">
        <div className="page-title">
          <TitleComponent title={'Klant aanmaken'} />
        </div>
        <div className="page-inner-content-home">
          <Grid container direction="row" justify="center" alignItems="center" spacing={32}>
            <Grid item xs={4}>
              <Link to="/createlead/customer/add/1">
                <Paper className="customer-option-container">
                  <div className="customer-option-inner-container">
                    <img src={require('../../../../images/business.svg')} className="option-icon" alt="business" />
                    <p className="option-text">Zakelijk</p>
                  </div>
                </Paper>
              </Link>
            </Grid>
            <Grid item xs={4}>
              <Link to="/createlead/customer/add/2">
                <Paper className="customer-option-container">
                  <div className="customer-option-inner-container">
                    <img src={require('../../../../images/customer.svg')} className="option-icon" alt="customer" />
                    <p className="option-text"> Particulier  </p>
                  </div>
                </Paper>
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="page-footer">
        <div className="page-footer-inner">
        </div>
      </div>
    </div>
  }
}

const connected = connect()(CustomerAddComponent);
export { connected as CustomerAddComponent };

