import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import './housingFaceModal.Style.css';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: '0 !important',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '100vh',
    height: '75vh',
    border: '0 !important'
  }
};

class HousingFaceModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    }

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  render() {
    var housingFace = this.props.housingFace
    var list = [];

    if (housingFace != null) {
      for (var index = 0; index < housingFace.housingElements.length; index++) {
        var housingElement = housingFace.housingElements[index];
        if (housingElement) {
          var elementsWithImplementation = housingElement.elementImplementations.filter((e) => e.count > 0);
          if (elementsWithImplementation && elementsWithImplementation.length > 0) {
            list.push(
              <TableBody key={`body-${index}`}>
                <TableRow key={`row-${index}`}>
                  <TableCell className="housing-element-name"> {housingElement.housingElementName}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
                {
                  elementsWithImplementation.map((elementImplementation, subIndex) => {
                    return (<TableRow key={`row-${index}-${subIndex}`}>
                    <TableCell></TableCell>
                    <TableCell>{elementImplementation.name}</TableCell>
                    <TableCell>{elementImplementation.count} stuks(s) </TableCell>
                  </TableRow>);
                  })
                }
              </TableBody>
            )
          }
        }
      }
    }

    return (
      <div>
        <img src={require('../../../../images/info.svg')} onClick={this.openModal} alt="information" />

        <Modal isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal} style={customStyles} contentLabel=":Label" >
          <div className="modal-wrapper">
            <div className="modal-header">
              <div className="modal-header-inner">
                <p className="mondal-title">Gevel informatie </p>
              </div>
            </div>
            <div className="modal-content">
              <div className="modal-inner-content">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Element</TableCell>
                      <TableCell>Implementatie</TableCell>
                      <TableCell>Aantal</TableCell>
                    </TableRow>
                  </TableHead>
                  {list}
                </Table>
              </div>
            </div>
            <div className="modal-footer">
              <Button size="large" className="bttn-search bttn-modal" onClick={this.closeModal}> Sluiten </Button>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

const connected = connect()(HousingFaceModal);
export { connected as HousingFaceModal };

