import { leadCustomerConstants } from './leadCustomer.Constants';
import { customerAddConstants } from './customer-add/customeradd.Constants';

const initialState = {
  searchCustomers: [],
  selectedCustomer: {},
}

export const LeadCustomerReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case leadCustomerConstants.CUSTOMER_SEARCH_SUCCES:
      return {
        searchCustomers: action.data.data
      }
    case leadCustomerConstants.CUSTOMER_SELECTED_SUCCES:
      return {
        ...state,
        selectedCustomer: action.data
      }
    case leadCustomerConstants.CUSTOMER_SEARCH_FAIL:
      return {};
    case leadCustomerConstants.RESET_LEADCUSTOMER:
      state = initialState;
      return state;
    case customerAddConstants.ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        selectedCustomer: action.data.data,
        searchCustomers: [action.data.data]
      }
    case customerAddConstants.ADD_CUSTOMER_FAIL:
      return {}
    default:
      return state;
  }
}

