import { LeadCalculationConstants} from './leadCalculation.Constants';

const initialState = { 
  foundationCalculationData: '',
  foundationCalculationType: '',
  foundationCalculationInput: '',
  
  paymentPlanCalculationData: '',
  paymentPlanCalculationType: '',
  paymentPlanCalculationInput: '',

  foundationIsLoaded: false,
  paymentIsLoaded: false,
  selectedCalculation: ''
}

export const leadCalculationReducer = (state, action) => { 
  state = state || initialState;

  switch(action.type) { 
    case LeadCalculationConstants.REQUEST_FOUNDATION_CALCULATION_SUCCESS: 
    var result = action.data;
    return { 
      ...state,
      foundationCalculationType: result.calculationType,
      foundationCalculationData: result.calculationResult,
      foundationCalculationInput: result.calculationInput,
      isValid: result.isValid,
      foundationIsLoaded: true
    }
    case LeadCalculationConstants.REQUEST_FOUNDATION_CALCULATION_FAIL: {
      return state;
    }
    case LeadCalculationConstants.REQUEST_PAYMENYPLAN_CALCULATION_FAIL: {
      return state;
    }
    case LeadCalculationConstants.REQUEST_PAYMENTPLAN_CALCULATION_SUCCESS: {
      return {
        ...state,
        paymentPlanCalculationData: action.data.calculationResult,
        paymentPlanCalculationType: action.data.calculationType,        
        paymentPlanCalculationInput: action.data.calculationInput,
        paymentFoundationIsValid: action.data.isValid,
        paymentIsLoaded: true
      }
    }
    case LeadCalculationConstants.RESET_LEADCALCULATIONS: {
      state = initialState;
      return state;
    }
    case LeadCalculationConstants.SELECTED_CALCULATION: { 
      return { 
        ...state,
        selectedCalculation: action.data
      }
    }
    default: 
      return state;
  }
}
