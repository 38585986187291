export const leadHousingPlanConstants = {
  GETLIST_PRODUCT_SUCCES: 'GETLIST_PRODUCT_SUCCES',
  GETLIST_PRODUCT_FAIL: 'GETLIST_PRODUCT_FAIL',

  STORE_HOUSINGPLANDATA: 'STORE_HOUSINGPLANDATA',

  HOUSING_PLAN_ABBODURAGE: 'HOUSING_PLAN_ABBODURAGE',
  HOUSING_PLAN_STARTYEAR: 'HOUSING_PLAN_STARTYEAR',

  RESET_LEADHOUSINGPLAN: 'RESET_LEADHOUSINGPLAN',
  RESET_LEADHOUSINGPLAN_PRODUCTS: 'RESET_LEADHOUSINGPLAN_PRODUCTS'
};
