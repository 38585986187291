import { photoPanelConstants } from './photoPanel.Constants';

const initialState = {
  // eslint-disable-next-line no-mixed-operators
  tempId: ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)),
  photos: []
}

export const photoPanelReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case photoPanelConstants.ADD_PHOTO:
      return {
        ...state,
        photos: [...state.photos, action.photo]
      }

    case photoPanelConstants.UPDATE_PHOTO_DESCRIPTION:
      const photos1 = [...state.photos];
      const photo = photos1.find(photo => photo.name === action.photo.name);
      photo.description = action.photo.description;

      return {
        ...state,
        photos: photos1
      };

    case photoPanelConstants.DELETE_PHOTO:
      const photos2 = [...state.photos];
      const index = photos2.indexOf(action.photo);

      if (~index) photos2.splice(index, 1);

      return {
        ...state,
        photos: photos2
      };

    case photoPanelConstants.RESET_PHOTOS:
      return initialState;

    default:
      return state;
  }
}

