import { leadFinishConstants } from './leadFinish.Constants';

const initialState = {
  mailSend: false,
  mailBody: ''
}

export const finishReducer = (state = initialState, action) => {
  switch (action.type) {
    case leadFinishConstants.MAIL_SUCCESS:
      return {
        mailSend: true
      };
    case leadFinishConstants.MAIL_FAIL:
      return {
        mailSend: false
      };
    default:
      return state; 
  }
}
