import { leadOverviewConstants } from './leadOverview.Constants';

const initialState = {
  leadId: 0,
  selectedCalculationType: 0,
  signed: false,
  email: null,
  comment: null
}

export const leadOverviewReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case leadOverviewConstants.ADD_LEAD_SUCCESS:
      return {
        leadId: action.data.leadId,
        selectedCalculationType: action.data.selectedCalculationType,
        email: action.data.email,
        signed: action.data.signed
      };
    case leadOverviewConstants.ADD_LEAD_FAIL:
      return {
      };
    case leadOverviewConstants.UPDATE_LEADCOMMENT: {
      return {
        ...state,
        comment: action.data
      };
    }
    case leadOverviewConstants.RESET_LEADOVERVIEW: {
      state = initialState;
      return state;
    }
    default:
      return state; 
  }
}
