import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { TitleComponent, HeaderComponent } from 'components';
import { CustomerComponent } from './customer/customer.Component';
import * as LeadCustomerAction from './leadCustomer.Action';

import './leadCustomer.Style.css';

class LeadCustomerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastname: '',
      zipcode: '',
      houseNumber: ''
    }

    this.handleLastName = this.handleLastName.bind(this);
    this.handleZipCode = this.handleZipCode.bind(this);
    this.handleHouseNumber = this.handleHouseNumber.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNextButton() {
    history.push('/createLead/houseType');
  }

  handleLastName(event) {
    this.setState({
      lastname: event.target.value
    })
  }

  handleZipCode(event) {
    this.setState({
      zipcode: event.target.value
    });
  }

  handleHouseNumber(event) {
    this.setState({
      houseNumber: event.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { lastname, zipcode, houseNumber } = this.state;

    var data = {
      lastname: lastname,
      zipCode: zipcode,
      houseNumber: houseNumber,
      organizationId: this.props.loggedInUser.user.organizationId
    };

    if (data != null) {
      this.props.dispatch(LeadCustomerAction.getSearchResults(data));
    }
  }

  newCustomerBttn() {
    history.push('/createLead/customer/add');
  }

  render() {
    return (
      <div className="page-wrapper">
        <div className="page-header">
          <HeaderComponent />
        </div>
        <div className="page-content">
          <div className="page-title">
            <TitleComponent title={'Klant'} />
          </div>
          <div className="page-inner-content-default">
            <div className="lead-customer-search-content">
              <div>
                <form name="search-lead-by-customer">
                  <Grid container justify="center">
                    <Grid item xs={8}>
                      <Grid container>
                        <Grid item xs={7}> <input type="text" className="form-control lastname-input" name="lastName" value={this.state.lastname} placeholder="Achternaam" onChange={this.handleLastName} autoComplete="off" /> </Grid>
                        <Grid item xs={3}> <input type="text" className="form-control zipcode-input " name="zipcode" value={this.state.zipcode} placeholder="Postcode" onChange={this.handleZipCode} autoComplete="off" /></Grid>
                        <Grid item xs={2}> <input type="text" className="form-control houseNumber-input" name="houseNumber" value={this.state.houseNumber} placeholder="Huisnummer" onChange={this.handleHouseNumber} autoComplete="off" /></Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                      <Button size="large" className="bttn-search" onClick={this.handleSubmit}>  <img src={require('../../../images/search.svg')} alt="search" /> </Button>
                    </Grid>
                    <Grid item xs={1}>
                      <Button size="large" className="bttn-add" onClick={this.newCustomerBttn}>  <img src={require('../../../images/add-white.svg')} alt="add-white" /> </Button>
                    </Grid>
                  </Grid>
                </form>
              </div>
              <div>
                <div className="customers-container">
                  {
                    this.props.searchedCustomers != null ? <div>
                       {
                          this.props.searchedCustomers.map((item, i) => (
                          <CustomerComponent key={i} data={item} />
                          ))
                       }
                       </div> 
                       : 
                       <div className="no-result-message">  
                          Er zijn geen resultaten gevonden.
                       </div> 
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    searchedCustomers: state.leadCustomers.searchCustomers,
    loggedInUser: state.login
  }
}

const connected = connect(mapStateToProps)(LeadCustomerComponent);
export { connected as LeadCustomerComponent };
