import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import Grid from '@material-ui/core/Grid';
import { TitleComponent, HeaderComponent } from 'components';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import PropTypes from 'prop-types';

import { OverviewHousingPlanComponent } from './overview/overviewHousingPlan.Component';
import { EditHousingPlanComponent } from './edit/editHousingPlan.Component';

import * as LeadHousingPlanAction from './leadHousingPlan.Action';
import * as HousingPlanCalculationAction from './leadHousingPlanCalculation.Action';

import QuestionBox from '../../../components/questionBox/questionBox.Component';

import './leadHousingPlan.Style.css';

function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

class LeadHousingPlanComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      headValue: 0,
      showBackModal: false
    }

    this.handleHeadTabChange = this.handleHeadTabChange.bind(this);
    this.loadData = this.loadData.bind(this);

    this.handleBack = this.handleBack.bind(this);
    this.handleBackQuestion = this.handleBackQuestion.bind(this);
    this.handleBackQuestionClose = this.handleBackQuestionClose.bind(this);
  }

  handleBackQuestion(e) {
    this.setState({showBackModal: true});
  }

  handleBack(e) {
    this.props.dispatch(LeadHousingPlanAction.reset());
    history.goBack();
  }

  handleBackQuestionClose(e) {
    this.setState({showBackModal: false});
  }

  handleNextButton() {
    history.push('/createLead/photo');
  }

  componentDidMount() {
    // If somewent back to this page we don't want to reload everything because nothing can be changed after this page.
    if(history.action === "PUSH") {
      this.loadData(this.props.housingPlanData.housingPlanDurage);
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.housingPlanData.housingPlanStart !== this.props.housingPlanData.housingPlanStart || 
       prevProps.housingPlanData.housingPlanDurage !== this.props.housingPlanData.housingPlanDurage){
        this.loadData(this.props.housingPlanData.housingPlanDurage, this.props.housingPlanData.housingPlanData);
    }
  }

  loadData(planDurage, currentHousingPlanData) {
    var data = HousingPlanCalculationAction.initialHousingPlanData(this.props.housingPlanData.housingPlanStart, planDurage, this.props.selectedHousingFaces, this.props.products, currentHousingPlanData);
    
    HousingPlanCalculationAction.calculatePrices(data);
    this.props.dispatch(LeadHousingPlanAction.storeHousingPlanData(data));
  }

  handleHeadTabChange = (event, value) => {
    this.setState({
      headValue: value
    });
  };

  render() {
    const { headValue } = this.state;

    var jsxToLoad;

    if(this.props.selectedHousingFaces.length !== 0){
      jsxToLoad = <div className="page-wrapper">
      <div className="page-header">
        <HeaderComponent />
      </div>
      <div className="page-content">
        <div className="page-title">
          <TitleComponent title={'Bestek'} onClick={this.handleBackQuestion} />
        </div>
        <div className="page-inner-content-default">
          {
            this.state.showBackModal && 
            <>
              <QuestionBox 
                title="Weet u het zeker?"
                message="Weet u zeker dat u terug wilt? Uw wijzigingen worden niet opgeslagen!"
                onSubmit={this.handleBack}
                onCancel={this.handleBackQuestionClose} />
            </>
          }
          <Grid container className="app-container">
            <Grid item xs={12}>
              <AppBar position="static" color="default" className="housing-plan-headInformation">
                <Tabs
                  value={headValue}
                  onChange={this.handleHeadTabChange}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab label="Alle Gevels" className="all-faces" />
                  {
                    this.props.selectedHousingFaces.map((row, i) => (
                      <Tab key={i} label={row.housingFace.name} />
                    ))
                  }
                </Tabs>
                {headValue === 0 && <TabContainer><OverviewHousingPlanComponent key={headValue} /> </TabContainer>}
                {
                  this.props.selectedHousingFaces.map((row, i) => (
                    headValue === (i + 1) && <TabContainer key={i}><EditHousingPlanComponent key={i} totalYears={this.props.housingPlanData.housingPlanDurage} startYear={this.props.housingPlanData.housingPlanStart} housingFaceSelectedData={row} /></TabContainer>
                  ))
                }
              </AppBar>
            </Grid>
          </Grid>
        </div>
      </div>      
      <div className="page-footer">
        <div className="page-footer-inner">
          <span onClick={() => this.handleNextButton()}> <span className="next-button-text">Volgende</span> <img src={require('../../../images/next.svg')} className="next-button" alt="next" /> </span>
        </div>
      </div>
  </div>;
    } else if(this.props.selectedHousingFaces.length === 0) {
      jsxToLoad = <div className="page-wrapper">
        <div className="page-header">
          <HeaderComponent />
        </div>
        <div className="page-content">
              <div className="page-title">
                <TitleComponent title={'Bestek'} />
             </div>
             <div className="page-inner-content-default page-inner-content-center-message">
              <p>U heeft geen gevelinformatie opgegeven, ga terug naar de vorige pagina om deze alsnog op te geven.</p>
             </div>
            </div>
       
      </div>;
    }

    return (
      jsxToLoad  
    )
  }
}

function mapStateToProps(state) {
  return {
    selectedHousingFaces: state.selectedImplementation.filteredHousingFaces,
    housingPlanData: state.housingPlanData,
    products: state.products.products
  }
}

const connectedLeadBestekComponent = connect(mapStateToProps)(LeadHousingPlanComponent);
export { connectedLeadBestekComponent as LeadHousingPlanComponent };