import { leadConfirmConstants } from './leadConfirm.Constants';

const initialState = {
  accessibilityConfirmed: false,
  repairs: []
}

export const leadConfirmReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case leadConfirmConstants.ACCESSIBILITY_CONFIRMED:
      return {
        ...state,
        accessibilityConfirmed: action.accessibilityConfirmed
      }

    case leadConfirmConstants.ADD_REPAIR:
      var repairs = [...state.repairs];
      repairs.push('');
      return {
        ...state,
        repairs: repairs
      };

    case leadConfirmConstants.UPDATE_REPAIR:
      const repairs1 = [...state.repairs];
      repairs1[action.index] = action.text;

      return {
        ...state,
        repairs: repairs1
      };

    case leadConfirmConstants.DELETE_REPAIR:
      const repairs2 = [...state.repairs];
      repairs2.splice(action.index, 1);

      return {
        ...state,
        repairs: repairs2
      };

    case leadConfirmConstants.RESET_CONFIRMATIONS:
      return initialState;
    default:
      return state;
  }
}

