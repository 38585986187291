import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

export class HousingTypeRowComponent extends Component {
  render() {
    var selected = 0;
    var housingType = this.props.item;

    if(this.props.selected)
    {
       selected = this.props.housingTypeId;
    }

    return (
      <Grid item md={2} xs={4} key={housingType.housingTypeId}>
        {
          selected === housingType.housingTypeId ?
              <Paper className="housetype-row housetype-row-selected">
                <Grid container style={{ height: "100%", width: "100%" }} alignItems="center">
                  <Grid item container style={{ maxHeight: "50%", height: "50%", width: "100%"}} alignItems="center" justify="center">
                    <CircularProgress />
                  </Grid>
                  <Grid item style={{ maxHeight: "50%", height: "50%", width: "100%" }}>
                    <div className="housingtype-name">{housingType.name} </div> 
                  </Grid>
                </Grid>
              </Paper>
            :
            <Paper className="housetype-row" onClick={this.props.selectedHouseType}>
              <Grid container style={{ height: "100%", width: "100%" }} alignItems="center">
                  <Grid item container style={{ maxHeight: "50%", height: "50%", width: "100%"}} alignItems="center" justify="center">
                    <img src={require('../../../../images/housetype-blue.svg')} alt="housingTypeBlue" />
                  </Grid>
                  <Grid item style={{ maxHeight: "50%", height: "50%", width: "100%" }}>
                    <div className="housingtype-name housing-type-name-notselected">{housingType.name} </div> 
                  </Grid>
                </Grid>
            </Paper>
        }
      </Grid>
     )
  }
}
