const API_URL_DEV = "https://localhost:44379/api/";
const API_URL_DEV2 = "http://bit-ntb006:60555/api/";

export const url = () => {
  if (window.location.hostname.includes("localhost")) {
    return API_URL_DEV;
  }
  else if (window.location.hostname.includes("bit-ntb006")) {
    return API_URL_DEV2;
  }  
  else {
    var parts = window.location.hostname.split(".");    
    return `${window.location.protocol}//${parts[0]}api.${parts[1]}.${parts[2]}/api/`;
  }
}
