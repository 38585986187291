import React, { Component } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from '../helpers/history';
import { PrivateRoute } from '../components/route/PrivateRoute';
import { trackPageView } from '../helpers/TelemetryService';

import { HomeComponent } from '../pages/home/home.Component';
import { LoginComponent } from '../pages/login/login.Component';
import { HousingTypeComponent } from '../pages/lead/housingType/housingType.Component';
import { HousingFaceComponent } from '../pages/lead/housingFace/housingFace.Component';
import { LeadCalculationComponent } from '../pages/lead/leadCalculation/leadCalculation.Component';
import { LeadOverviewComponent } from '../pages/lead/leadOverview/leadOverview.Component';
import { LeadCustomerComponent } from '../pages/lead/leadCustomer/leadCustomer.Component';
import { LeadFinishComponent } from '../pages/lead/leadFinish/leadFinish.Component';
import { LeadHousingPlanComponent } from '../pages/lead/leadHousingPlan/leadHousingPlan.Component';
import { CustomerAddComponent } from '../pages/lead/leadCustomer/customer-add/customerAdd.Component';
import { CreateCustomerComponent } from '../pages/lead/leadCustomer/customer-add/create/create.Component';
import LeadConfirmComponent from '../pages/lead/leadConfirm/leadConfirm.Component';
import LeadPhotoComponent from '../pages/lead/leadPhoto/leadPhoto.Component';
import ErrorBoundary from '../components/error/ErrorBoundary';

import VersionComponent from '../pages/version/version.Component';
import IdleTimerViewComponent from '../pages/idleTimer/idleTimerView.Component';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldCheckActive: true
    }
  }

  setUserActive() {
    var expiredTime = localStorage.getItem("_expiredTime");
    if(expiredTime){
      var dateNow = Date.now();
      if (Math.floor(expiredTime / 1000) !== Math.floor(dateNow / 1000)) {
        localStorage.setItem("_expiredTime", dateNow);
      }
    }
  }

  checkUserActive() {
    var expiredTime = localStorage.getItem("_expiredTime");
    if(expiredTime){
      if (Math.floor((Date.now() / 1000) - 3600) > expiredTime / 1000){ 
        window.location.reload();
      }
    }
  }

  componentDidMount() {
    const { user } = this.props;
    history.listen(location => trackPageView(location.pathname, user.username));

    window.addEventListener("click", () => this.setUserActive());

    // Enable when app is stable enough.
    // window.setInterval(() => {
    //   this.checkUserActive();
    // }, 5000);
  }

  render() {
    let idleTimerView = <IdleTimerViewComponent />;
    let activeUser = true;
    let expiredTime = localStorage.getItem("_expiredTime");
    if(expiredTime && Math.floor((Date.now() / 1000) - 3600) > expiredTime / 1000){
      activeUser = false;
    }

    let toRender;
    var currentVersion = process.env.REACT_APP_VERSION;
    console.log("current version: ", currentVersion);;
    var versionInBrowser = localStorage.getItem('version');
    console.log("Version in browser ", versionInBrowser);
    if (versionInBrowser && currentVersion !== versionInBrowser) {
      toRender = <VersionComponent />
    } else {
      toRender = 
        <ErrorBoundary>
          <Router history={history}>
            <Switch>
              <Route exact path='/' component={LoginComponent} />
              <PrivateRoute exact path='/home' component={HomeComponent} />
              <PrivateRoute exact path='/createLead/photo' component={LeadPhotoComponent} />
              <PrivateRoute exact path='/createLead/confirm' component={LeadConfirmComponent} />
              <PrivateRoute exact path='/createLead/houseType' component={HousingTypeComponent} />
              <PrivateRoute exact path='/createLead/houseFace' component={HousingFaceComponent} />
              <PrivateRoute exact path='/createLead/housingPlan' component={LeadHousingPlanComponent} />
              <PrivateRoute exact path='/createLead/calculation' component={LeadCalculationComponent} />
              <PrivateRoute exact path='/createLead/customer' component={LeadCustomerComponent} />
              <PrivateRoute exact path='/createLead/customer/add' component={CustomerAddComponent} />
              <PrivateRoute exact path='/createLead/customer/add/:id' component={CreateCustomerComponent} />
              <PrivateRoute exact path='/createLead/overview' component={LeadOverviewComponent} />
              <PrivateRoute exact path='/createLead/finish' component={LeadFinishComponent} />
              <Route path='*' component={HomeComponent} />
            </Switch>
          </Router>
        </ErrorBoundary>;
    }

    return (
      // Should be tested some more, enable in the furture when te app is stable enough.
      // <>{ activeUser ? toRender : idleTimerView }</>
      toRender
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.login && state.login.loggedIn && state.login.user
  };
}

const connectedApp = connect(mapStateToProps)(App);
export { connectedApp as App }; 
