import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { HeaderComponent } from 'components';
import { resetWholeState } from '../lead/leadOverview/leadOverview.Action';

import './home.Style.css';

class HomeComponent extends Component {
  render() {
    const { dispatch, user } = this.props;

    const startNewLead = () => {
      if (user.organizationId !== null) {
        dispatch(resetWholeState());
        history.push('/createLead/customer');
      }
    }

    const continueLead = () => {
      if (user.organizationId !== null) {
        history.push('/createLead/houseFace');
      }
    }

    return (
      <div className="page-wrapper">
        <div className="page-header">
          <HeaderComponent />
        </div>
        <div className="page-content">
          <div className="page-title">
            <h1 className="home-title">Welkom terug, {user.fullname}</h1>
          </div>
          <div className="page-inner-content-home">
            <Grid container direction="row" justify="center" alignItems="center" spacing={32}>
              <Grid item xs={4}>
                <Paper className="home-option-container">
                  <div className="home-option-inner-container" onClick={startNewLead}>
                    { user.organizationId !== null ?
                        <Fragment>
                          <img src={require('../../images/add.svg')} className="option-icon" alt="add" />
                          <p className="option-text">Nieuwe verkoopkans aanmaken </p>
                        </Fragment> :
                        <p className="no-org-message">Uw gebruikersaccount is niet gekoppeld aan een organisatie. U kunt met dit systeem geen verkoopkansen aanmaken. Neem contact op met GlansGarant.</p>
                    }
                  </div>
                </Paper>
              </Grid>
              {this.props.filteredHousingFaces && this.props.filteredHousingFaces.length > 0 &&
                <Grid item xs={4}>
                  <Paper className="home-option-container">
                    <div className="home-option-inner-container" onClick={continueLead}>
                      <img src={require('../../images/customer.svg')} className="option-icon" alt="add" />
                      <p className="option-text">Verder met openstaande verkoopkans</p>
                    </div>
                  </Paper>
                </Grid>
              }
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  let filteredHousingFaces;
  if(state.selectedImplementation && state.selectedImplementation.filteredHousingFaces){ 
    filteredHousingFaces = state.selectedImplementation.filteredHousingFaces;
  }
  return {
    user: state.login.user,
    filteredHousingFaces: filteredHousingFaces
  };
}

const connectedHomeComponent = connect(mapStateToProps)(HomeComponent);
export { connectedHomeComponent as HomeComponent };
