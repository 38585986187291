import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { trackTrace, SeverityLevel } from '../../../../helpers/TelemetryService';
import * as LeadCalculationAction from '../leadCalculation.Action';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import HelpIcon from '@material-ui/icons/Help';

import '../plan.Style.css';

const customStyles = {
    width: "100%",
    borderRadius: "0"
  };

const toLowStyle = {
  outline: "2px solid red"
};

class PaymentPlanComponent extends Component {
    constructor(props) {
        super(props);

        let onceAmount = 2500;
        if(props.paymentPlanCalculation && props.paymentPlanCalculation.paymentPlanCalculationData) {
          if(props.paymentPlanCalculation.paymentPlanCalculationData.onceAmount && props.paymentPlanCalculation.paymentPlanCalculationData.onceAmount >= onceAmount){
            onceAmount = Math.round(props.paymentPlanCalculation.paymentPlanCalculationData.onceAmount);
          }
        }

        this.state = {
          paymentPlanMonths: props.paymentPlanCalculation && props.paymentPlanCalculation.paymentPlanCalculationInput ? props.paymentPlanCalculation.paymentPlanCalculationInput.months : 168,
          onceAmount: onceAmount,
          open: false,
          toLow: false
        }
  
        this.changeOnceAmount = this.changeOnceAmount.bind(this);
        this.changeMonths = this.changeMonths.bind(this);
        this.onChangeMonth = this.onChangeMonth.bind(this);
        this.onChangeOnceAmount = this.onChangeOnceAmount.bind(this);
      }

      isNumeric(str) {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
               !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
      }

      onChangeOnceAmount(event) {
        this.setState({
          onceAmount: event.target.value,
          toLow: event.target.value < 2500
        });

        if(this.isNumeric(event.target.value) && event.target.value >= 2500){
          this.changeOnceAmount(event.target.value);
        }
      }

      changeOnceAmount(onceAmount) {
        if (onceAmount >= 0) {
          var roundedValue = parseFloat(onceAmount).toFixed(0);
          this.props.dispatch(LeadCalculationAction.getPaymentPlanCalculation(this.props.housingPlanData, this.state.paymentPlanMonths, roundedValue));
          this.setState({ onceAmount: roundedValue, toLow: false});
        }
      }

      onChangeMonth(event) {
        if(event.target.value !== ""){
          var newValue = (this.state.paymentPlanMonths === "") ? this.props.leadFoundationCalculation.paymentPlanCalculationInput.months : event.target.value;

          if(newValue !== ""){
            this.setState({
              paymentPlanMonths: newValue
            });
        
            this.changeMonths(newValue); 
          }
        }
      }
    
      changeMonths(paymentPlanMonths) {
        if (paymentPlanMonths > 0 && paymentPlanMonths < 999) {
          this.props.dispatch(LeadCalculationAction.getPaymentPlanCalculation(this.props.housingPlanData, paymentPlanMonths, this.state.onceAmount));
        }
      }

    render() {
        var paymentPlanMonthPrice, paymentPlanTotalMonths, foundationMonthPrice, foundationTotalMonths, interestRate, onceAmount, lowestPrice, lowestBalanceYear, totalPriceInclFinance;
        var hasCalculationData = false;
        var isValid = false;

        const getBalanceClass = (balance) => !isValid ? "tooNegativeBalance" : (balance < 0) ? "negativeBalance" : "positiveBalance";

        const handleTooltipClose = () => {
          this.setState({open: false});
        };
      
        const handleTooltipOpen = () => {
          this.setState({open: true});
        };      

        const tooltipError = <ClickAwayListener onClickAway={handleTooltipClose}>
        <div style={{ display: "inline"}}>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={this.state.open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Bij deze calculatie komt het saldo op enig moment onder het maximaal toelaatbare negatieve saldo. U kunt het onderhoudsplan wel opslaan, maar u dient daarna contact op te nemen met het hoofdkantoor voor akkoord."
          >
            <HelpIcon onClick={handleTooltipOpen}>Click</HelpIcon>
          </Tooltip>
        </div>
      </ClickAwayListener>;

        if (this.props.paymentPlanCalculation != null) {
          var calculation = this.props.paymentPlanCalculation;

          if (calculation.paymentPlanCalculationData && calculation.paymentPlanCalculationData !== "" && calculation.paymentPlanCalculationInput && calculation.paymentPlanCalculationInput !== ""){
            paymentPlanMonthPrice = calculation.paymentPlanCalculationData.monthAmountFinance.toFixed(2);
            paymentPlanTotalMonths = calculation.paymentPlanCalculationInput.monthsFinance;
            foundationMonthPrice = calculation.paymentPlanCalculationData.monthAmount.toFixed(2);
            foundationTotalMonths = calculation.paymentPlanCalculationInput.months;
            interestRate = calculation.paymentPlanCalculationInput.monthInterest;
            onceAmount = calculation.paymentPlanCalculationData.onceAmount.toFixed(2);
            lowestBalanceYear = calculation.paymentPlanCalculationData.lowestBalanceYear.year;
            lowestPrice = calculation.paymentPlanCalculationData.lowestBalanceYear.balance.toFixed(2);
            isValid = calculation.isValid;

            totalPriceInclFinance = calculation.paymentPlanCalculationData.sumIncludingFinance.toFixed(2);

            hasCalculationData = true;
          }
        }
    
        if (!hasCalculationData) {
          trackTrace("No calculation data in paymentPlan.Component", SeverityLevel.Warning, { calculation: this.props.paymentPlanCalculation });
        }

        return (
            <Grid item lg={4} md={4} >
            <Paper className="calculation-plan-paper">
              <div className="calculation-plan-header">
                <div className="calculation-plan-header-inner">
                  {this.props.title}
                  <p className="subtitle">Maandelijks abonnement</p>
                </div>
              </div>
              {
                hasCalculationData && <Grid container spacing={16}>
                <Grid item md={12} lg={12}>
                  <div className="calculation-plan-body">
                    <div className="calculation-plan-resume-body">
                      <Grid container spacing={8}>
                        <Grid item xs={5}> Aantal maanden </Grid><Grid item xs={1}> : </Grid><Grid item xs={6}> {foundationTotalMonths}</Grid>
                        <Grid item xs={5}> Aantal maanden financiering </Grid><Grid item xs={1}> : </Grid><Grid item xs={6}> {paymentPlanTotalMonths}</Grid>
                        {
                          !isValid && 
                          <><Grid item xs={5}> Laagste saldo </Grid><Grid item xs={1}> : </Grid><Grid item xs={6} className={getBalanceClass(lowestPrice)}> {lowestBalanceYear} - &#8364; {lowestPrice}{tooltipError}</Grid></>
                        }
                        {
                          isValid && 
                          <><Grid item xs={5}> Laagste saldo  </Grid><Grid item xs={1}> : </Grid><Grid item xs={6} className={getBalanceClass(lowestPrice)}> {lowestBalanceYear} - &#8364; {lowestPrice}</Grid></>
                        }
                        <Grid item xs={5}> Totaal abonnement incl. financiering </Grid><Grid item xs={1}> : </Grid><Grid item xs={6}> {totalPriceInclFinance}</Grid>
                        <Grid item xs={5}> Eenmalig factureren </Grid><Grid item xs={1}> : </Grid><Grid item xs={6}> {onceAmount} </Grid>
                        <Grid item xs={5}> Rente financiering</Grid><Grid item xs={1}> : </Grid><Grid item xs={6}> {interestRate}%</Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
              }
              {
                !hasCalculationData && <Grid container spacing={16}>
                  <Grid item md={12} lg={12}>
                    <div className="calculation-plan-body">
                      <div className="calculation-plan-resume-body">
                        Door een technische storing kon de berekening niet worden gemaakt. Probeer het later opnieuw.
                      </div>
                    </div>
                  </Grid>
                </Grid>
              }
              {
               hasCalculationData && <Grid item md={12} lg={12} xs={12}>
                 <div>
                  <div className="month-duration-change">
                    <Grid container>
                      <Grid item xs={3} spacing={0}>
                        <Button style={customStyles} size="large" className="button-refresh housing-plan-add-bttn abbo-duration-button"><img src={require('../../../../images/calendar.svg')} alt="calendar"/></Button>
                      </Grid>
                      <Grid item xs={6}>
                        <input type="number" className="form-control refresh-txt" name="abboDuration" placeholder="Wijzig maanden" autoComplete="off" value={this.state.paymentPlanMonths} onChange={(e) => this.onChangeMonth(e)} />
                      </Grid>
                      <Grid item xs={3}>
                        <Button size="large" className="button-refresh housing-plan-add-bttn abbo-duration-button" onClick={() => this.changeMonths(this.state.paymentPlanMonths)}>  <img src={require('../../../../images/refresh.svg')} alt="refresh" /> </Button>
                      </Grid>
                    </Grid>
                    </div>
                    <div className="month-duration-change">
                      <Grid container>
                        <Grid item xs={3}>
                          <Button style={customStyles} size="large" className="button-refresh housing-plan-add-bttn abbo-duration-button"><span style={{ fontSize: "30px" }}>€</span></Button>
                        </Grid>
                        <Grid item xs={6}>
                          <input type="text" style={this.state.toLow ? toLowStyle : null} className="form-control refresh-txt"  name="abboDuration" placeholder="Financieringsbedrag" autoComplete="off" value={this.state.onceAmount} onChange={(e) => this.onChangeOnceAmount(e)} />
                        </Grid>
                        <Grid item xs={3}>
                          <Button size="large" className="button-refresh housing-plan-add-bttn abbo-duration-button calculation-left-button" onClick={() => this.changeOnceAmount(onceAmount >= 2500 ? onceAmount : 2500)}>  <img src={require('../../../../images/refresh.svg')} alt="refresh" /> </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  {
                    this.state.toLow && 
                      <div className="calculation-plan-price" onClick={() => alert("Te lenen bedrag moet groter of kleiner zijn dan 2500")} style={{ margin: "auto" }}>
                        &#8364; {foundationMonthPrice} <span style={{ color: "gray", fontSize:"18px"}}>(+ &#8364; {paymentPlanMonthPrice})</span>
                    </div>
                  }
                  {
                    !this.state.toLow && 
                      <div className="calculation-plan-price" onClick={() => this.props.handleCalculation(this.props.calculationName)} style={{ margin: "auto" }}>
                      &#8364; {foundationMonthPrice} <span style={{ color: "gray", fontSize:"18px"}}>(+ &#8364; {paymentPlanMonthPrice})</span>
                      <p className="choose-txt"> Kies dit pakket </p>
                    </div>
                  }
                  
                </Grid>
              }
              </Paper>
          </Grid>
        )
    }
}

function mapStateToProps(state) {
    return {
        paymentPlanCalculation: {
          paymentPlanCalculationData: state.leadCalculation.paymentPlanCalculationData,
          paymentPlanCalculationInput: state.leadCalculation.paymentPlanCalculationInput,
          paymentPlanCalculationType: state.leadCalculation.paymentPlanCalculationType,
          isValid: state.leadCalculation.paymentFoundationIsValid
        },
        housingPlanData: state.housingPlanData
    }
}

const connected = connect(mapStateToProps)(PaymentPlanComponent);
export { connected as PaymentPlanComponent };