import React from 'react'
import { trackException } from '../../helpers/TelemetryService';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo
    })

    trackException(error);
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div style={{ color: 'white', margin: '2em' }}>
          <h2>Er is een onverwachte fout opgetreden.</h2>
          <p>De gegevens van deze fout zijn verzonden voor analyze. Excuses voor het ongemak. Probeert u het alstublieft opnieuw.</p>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

export default ErrorBoundary;
