import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import * as LeadCustomerAction from '../leadCustomer.Action';

import './customer.Style.css';

class CustomerComponent extends Component {

  handleSelectedCustomer(customer) {
    this.props.dispatch(LeadCustomerAction.storeSelectedCustomer(customer));
    history.push('/createLead/houseType');
  }

  render() {
    return (
      <div className="search-customer-wrapper">
        <div className="search-lead-left" onClick={() => this.handleSelectedCustomer(this.props.data)}>
          <div className="search-lead-left-inner">
            <img src={require('../../../../images/users.svg')} alt="users" />
          </div>
        </div>
        <div className="search-lead-right" onClick={() => this.handleSelectedCustomer(this.props.data)}>
          <div className="search-customer-right-inner">
            <div className="customer-information">
              <p className="customer-name"> {this.props.data.name} </p>
              <p>  {this.props.data.street} {this.props.data.houseNumber} {this.props.data.houseNumberAddition} <br /> {this.props.data.zippCode} {this.props.data.city}  </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const connected = connect()(CustomerComponent);
export { connected as CustomerComponent };
