import { leadConfirmConstants } from './leadConfirm.Constants';

export const accessibilityConfirmed = accessibilityConfirmed => ({
  type: leadConfirmConstants.ACCESSIBILITY_CONFIRMED,
  accessibilityConfirmed: accessibilityConfirmed
})

export const addRepair = () => ({
  type: leadConfirmConstants.ADD_REPAIR
})

export const deleteRepair = index => ({
  type: leadConfirmConstants.DELETE_REPAIR,
  index: index
})

export const updateRepair = (index, text) => ({
  type: leadConfirmConstants.UPDATE_REPAIR,
  index: index,
  text: text
})

export const resetConfirmations = () => ({
  type: leadConfirmConstants.RESET_CONFIRMATIONS
})
