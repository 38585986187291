export function handlePriceCorrection(year, housingPlanData, correctionPrice) {
  var yearPlanData = housingPlanData.find((e) => e.year === parseInt(year));
  var hardMinimum = 75; // Prices may never be less than this number!!!
  if (yearPlanData != null) {
    if(yearPlanData.yearTotalPrice + Number(correctionPrice) < hardMinimum) {
      // User inserted an invalid correcion
      yearPlanData.yearTotalPriceWithCorrection = yearPlanData.yearTotalPrice
      yearPlanData.correctionPrice = 0;
    } else {
      yearPlanData.yearTotalPriceWithCorrection = yearPlanData.yearTotalPrice + Number(correctionPrice);
      yearPlanData.correctionPrice = correctionPrice;
    }
  }
}

export function getTotalPrice(housingPlanData) {
  var totalPrice = 0;

  for (var i = 0; i < housingPlanData.length; i++) {
    totalPrice += Number(housingPlanData[i].yearTotalPriceWithCorrection);
  }

  return totalPrice;
}
