import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import './leadHousingPlan.Style.css';

class LeadHousingPlanComponent extends Component {

    render() {
        return <div>
            <Paper className="paper-default">
                <div className="customer-information-inner scroll-y">
                    <p className="information-title"> Meerjaren onderhoudsplan </p>

                    <Table className="customer-information-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Jaar</TableCell>
                                <TableCell>Korte omschrijving</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                this.props.housingPlan.map((item, i) => (
                                    <TableRow>
                                        <TableCell>{item.year}</TableCell>
                                        <TableCell>{item.selectedProductDescription} </TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </div>
    }
}

function mapStateToProps(state) {
    return {
        housingPlan: state.housingPlanData.housingPlanData
    }
}

const connected = connect(mapStateToProps)(LeadHousingPlanComponent);
export { connected as LeadHousingPlanComponent };
