import { leadHousingPlanConstants } from './leadHousingPlan.Constants';

export function storeHousingPlanData(data) {
  return dispatch => {
    dispatch({ type: leadHousingPlanConstants.STORE_HOUSINGPLANDATA, data });
  }
}

export function reset() {
  return dispatch => {
    dispatch({ type: leadHousingPlanConstants.RESET_LEADHOUSINGPLAN });
  }
}

export function getColumns(startYear, years) {
  var columns = [];

  var firstColumn = {
    name: 'overview',
    title: 'Overzicht'
  };

  columns.push(firstColumn);

}

export function getColumnStyle(years) {
  var columns = [];

  var firstColumn = {
    columnName: 'overview',
    width: 400
  };

  columns.push(firstColumn);
}
