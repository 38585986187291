import { history } from 'helpers/history';
import {headerConstants} from './header.Constants';

export function logout() {
    return dispatch => {
        dispatch(Logout());

        localStorage.removeItem('user');
        localStorage.removeItem('reduxState');
    
        history.push('/');
        window.location.reload();
         
        function Logout() { return { type: headerConstants.LOGOUT } }
    }
}