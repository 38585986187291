import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { authHeader } from 'helpers/authHeader';
import * as api from 'helpers/api.js';
import { HeaderComponent, TitleComponent } from 'components';
import * as LeadFinishAction from './leadFinish.Action';
import { ClipLoader } from 'react-spinners';
import { Editor } from '@tinymce/tinymce-react';
import { history } from 'helpers/history';
import Grid from '@material-ui/core/Grid';
import { headerConstants } from '../../../components/layout/header/header.Constants';

import './leadFinish.Style.css';

class LeadFinishComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: this.props.leadOverview.email,
      body: '',
      loading: false,
      sentMail: false
    }

    this.handleEmail = this.handleEmail.bind(this);
    this.handleBody = this.handleBody.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.home = this.home.bind(this);
  }

  componentDidMount() {
    const { leadOverview } = this.props;
    this.getEmailBody(leadOverview.leadId);
  }

  getEmailBody(leadId) {
    var header = authHeader();

    const requestOptions = {
      method: 'GET',
      headers: header
    };

    fetch(`${api.url()}Mail/GetEmailBody/${leadId}`, requestOptions)
      .then(data => data.json())
      .then(mailBody => this.setState({ body: mailBody }));
  }

  handleEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  handleBody(value) {
    this.setState({
      body: value
    });
  }

  handleSubmit(e) {
    const { leadOverview, dispatch } = this.props;

    e.preventDefault();

    this.setState({
      loading: true
    })

    const { email, body } = this.state;
    var leadId = leadOverview.leadId;

    if (email && leadId > 0) {
      dispatch(LeadFinishAction.sendEmail(email, body, leadId))
        .then(() => {
          this.setState({ loading: false, sentMail: true });
          dispatch({ type: headerConstants.HARD_RESET });
        });
    }
  }

  home() {
    const { dispatch } = this.props;
    dispatch({ type: headerConstants.HARD_RESET });
    history.push('/home');
  }

  render() {
    const { email, body, sentMail } = this.state;
    const { leadOverview } = this.props;

    return (
      <div className="page-wrapper">
        <div className="page-header">
          <HeaderComponent />
        </div>
        <div className="page-content">
          {
            !sentMail &&
            <div>
              <div className="page-title">
                <TitleComponent title={'Onderhoudsplan aangemaakt'} hideBackButton={true} />
              </div>
              <div className="page-inner-content-default">
                <div className="finish-content">
                  <p className="finish-message">Bedankt, het onderhoudsplan is succesvol opgeslagen. </p>
                  {
                    this.props.leadOverview.selectedCalculationType === 200 && 
                      <p className="finish-message">Er wordt contact met u opgenomen wanneer de financiering akkoord is.</p>
                  }
                  {
                    leadOverview.signed && 
                    <Grid container spacing={16}>
                      <Grid item md={8} lg={12}>
                        <form name="login-form">
                          <div className="form-group">
                            <div className="login-form-placeholer email-placeholder">Email</div>
                            <input type="text" className="form-control login-form-input" name="email" value={email} onChange={this.handleEmail} autoComplete="off" />
                          </div>
                          <div className="form-group">
                            <div className="login-form-placeholer email-placeholder">Bericht</div>
                            {
                              body && <Editor value={body} onEditorChange={this.handleBody} init={{ height: 300 }} />
                            }
                          </div>
                        </form>
                      </Grid>
                      <Grid item md={4} lg={12}>
                        {
                          body && <Button onClick={this.handleSubmit} className="bttn-mail">Mail contract &amp; onderhoudsplan &nbsp;&nbsp; <ClipLoader sizeUnit={"px"} size={25} color={'white'} loading={this.state.loading} /></Button>
                        }
                      </Grid>
                    </Grid>
                  }
                </div>
              </div>
            </div>
          }       
          {
            sentMail &&
            <div>
              <div className="page-title">
                <TitleComponent title={'Mail verzonden'} hideBackButton={true} />
              </div>
            </div>
          }
          {
            (sentMail || !leadOverview.signed) &&
            <div>
              <div className="page-inner-content-default">
                <div className="finish-content">
                  <p></p>
                  <Button onClick={this.home} className="bttn-mail">Terug naar startpagina</Button>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    leadOverview: state.leadOverview
  };
}

const connected = connect(mapStateToProps)(LeadFinishComponent);
export { connected as LeadFinishComponent };
