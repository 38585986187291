import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TitleComponent, HeaderComponent } from 'components';
import Paper from '@material-ui/core/Paper';
import { history } from 'helpers/history';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import QuestionBox from '../../../../../components/questionBox/questionBox.Component';
import { ClipLoader } from 'react-spinners';

import * as CustomerAddAction from '../customerAdd.Action';

import './create.Style.css';

class CreateCustomerComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyName: '',
      companyContactName: '',
      personBegin: 0,
      initials: '',
      personInsert: '',
      personName: '',
      email: '',
      phoneLine: '',
      phoneNumber: '',
      street: '',
      zipCode: '',
      houseNumber: '',
      houseNumberAddition: '',
      city: '',

      error: [],
      isLoading: false
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.onQuestionBoxClose = this.onQuestionBoxClose.bind(this);
    this.onQuestionBoxSubmit = this.onQuestionBoxSubmit.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount(){
    if(this.state.isLoading) {
      this.setState({isLoading: false});
    }
  }

  handleBack() {
    history.goBack();
  }

  submit(){
    const { companyName, companyContactName, initials, personBegin, personInsert, personName, email, phoneNumber, phoneLine, street, zipCode, houseNumber, houseNumberAddition, city } = this.state;

    const capitalizeAll = (s) => {
      if (s !== null && typeof s !== 'string') return ''
      return s.toUpperCase()
    }

    const capitalize = (s) => {
      if (s !== null && typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
    
    if (((companyName && companyContactName) || personName) && street && zipCode && city) {
      var formData = {
        customerOption: this.props.match.params.id,
        companyName: companyName,
        companyContactPerson: companyContactName,
        initials: capitalizeAll(initials),
        personBegin: personBegin,
        personInsert: personInsert,
        personName: capitalize(personName),
        email: email,
        phoneNumber: phoneNumber,
        phoneLine: phoneLine,
        street: street,
        zipCode: zipCode,
        houseNumber: houseNumber,
        houseNumberAddition: houseNumberAddition,
        city: city,
        organizationId: this.props.loggedInUser.organizationId
      }

      this.props.dispatch(CustomerAddAction.AddCustomer(formData));
      this.setState({ isLoading: true});
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    
    let isValid = this.validate();
    if(!isValid){
      return;
    }

    this.submit();
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  validate() {
    let missingInformation  = [];
    const isBusiness = (this.props.match.params.id === "1");
    const { companyName, companyContactName, initials, personName, email, phoneNumber, phoneLine, street, zipCode, houseNumber, city } = this.state;
    if (isBusiness) {
      if(!companyContactName) {
        missingInformation.push("Contactgegevens bedrijf (verplicht)");
      }
      if(!companyName) {
        missingInformation.push("Bedrijfsnaam (verplicht)");
      }
    } else {
      if(!personName) {
        missingInformation.push("Achternaam (verplicht)");
      }
      if(!initials) {
        missingInformation.push("Initialen");
      }
    }

    if(!email) {
      missingInformation.push("E-mailadres");
    }

    if(!phoneNumber && !phoneLine) {
      missingInformation.push("(Mobiel)telefoonnummer");
    }

    if(!street) {
      missingInformation.push("Straat (verplicht)");
    }

    if(!zipCode) {
      missingInformation.push("Postcode (verplicht)");
    }

    if(!houseNumber) {
      missingInformation.push("Huisnummer");
    }

    if(!city) {
      missingInformation.push("Plaats (verplicht)");
    }
    if(missingInformation.length != 0){
      this.setState({"error": missingInformation});
      return false;
    }

    return true;
  }

  onQuestionBoxClose() {
    this.setState({"error": []});
  }

  onQuestionBoxSubmit() {
    this.setState({"error": []});
    this.submit();
  }

  render() {
    const { error, initials, companyName, companyContactName, personBegin, personInsert, personName, email, phoneNumber, phoneLine, street, zipCode, houseNumber, houseNumberAddition, city } = this.state;
    const isBusiness = (this.props.match.params.id === "1");

    return <div className="page-wrapper">
      <div className="page-header">
        <HeaderComponent />
      </div>
      <div className="page-content">
        <div className="page-title">
          {
            isBusiness ? <TitleComponent title={'Zakelijke klant aanmaken'} /> : <TitleComponent title={'Particuliere klant aanmaken'} />
          }
        </div>
        <div className="page-inner-content-default">
          {
            error.length > 0 && 
              <QuestionBox onSubmit={this.onQuestionBoxSubmit} onCancel={this.onQuestionBoxClose} message="Een aantal velden zijn niet ingevuld, weet u zeker dat u door wilt gaan?" items={error} title="De volgende gegevens zijn niet ingevuld (!)"></QuestionBox>
          }
          <Grid container spacing={16} className="customer-add-grid">
            <Grid item xs={12}>
              <form name="add-customer-form" onSubmit={this.handleSubmit}>
                <Paper className="create-customer-paper">
                  {
                    isBusiness ? <div>
                      <p className="create-customer-paper-subtitle">Bedrijf</p>

                      <Grid container spacing={16}>
                        <Grid item xs={12}>
                          <div className="form-group">
                            <div className="login-form-placeholer">Bedrijfsnaam*</div>
                            <input type="text" className="form-control login-form-input" name="companyName" value={companyName} onChange={this.handleChange} autoComplete="off" />
                          </div>
                          <div className="form-group">
                            <div className="login-form-placeholer">Contactpersoon*</div>
                            <input type="text" className="form-control login-form-input" name="companyContactName" value={companyContactName} onChange={this.handleChange} autoComplete="off" />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                      :
                      <div>
                        <p className="create-customer-paper-subtitle">Relatie</p>

                        <Grid container spacing={16}>
                          <Grid item xs={2}>
                            <div className="form-group">
                              <div className="login-form-placeholer">Aanhef</div>
                              <Select name="personBegin" className="form-control login-form-input" onChange={this.handleChange} value={personBegin}>
                                {CustomerAddAction.loadPersonBegin()}
                              </Select>
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div className="form-group">
                              <div className="login-form-placeholer">Initialen</div>
                              <input type="text" className="form-control login-form-input capitilize-all" name="initials" value={initials} onChange={this.handleChange} autoComplete="off" />
                            </div>
                          </Grid>
                          <Grid item xs={2}>
                            <div className="form-group">
                              <div className="login-form-placeholer">Tussenvoegsel</div>
                              <input type="text" className="form-control login-form-input" name="personInsert" value={personInsert} onChange={this.handleChange} autoComplete="off" />
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className="form-group">
                              <div className="login-form-placeholer">Achternaam</div>
                              <input type="text" className="form-control login-form-input capitilize-first" name="personName" value={personName} onChange={this.handleChange} autoComplete="off" />
                            </div>

                          </Grid>
                        </Grid>

                      </div>
                  }

                  <p className="create-customer-paper-subtitle">Contact</p>

                  <Grid container spacing={16}>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Telefoonnummer</div>
                        <input type="text" className="form-control login-form-input" name="phoneLine" value={phoneLine} onChange={this.handleChange} autoComplete="off" />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Mobiel</div>
                        <input type="text" className="form-control login-form-input" name="phoneNumber" value={phoneNumber} onChange={this.handleChange} autoComplete="off" />
                      </div>

                    </Grid>
                  </Grid>

                  <div className="form-group password-form-group">
                    <div className="login-form-placeholer">Email</div>
                    <input type="text" className="form-control login-form-input" name="email" value={email} onChange={this.handleChange} autoComplete="off" />
                  </div>

                  <p className="create-customer-paper-subtitle">Adres</p>
                  <Grid container spacing={16}>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Straat*</div>
                        <input type="text" className="form-control login-form-input" name="street" value={street} onChange={this.handleChange} autoComplete="off" />
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Huisnummer</div>
                        <input type="text" className="form-control login-form-input" name="houseNumber" value={houseNumber} onChange={this.handleChange} autoComplete="off" />
                      </div>
                    </Grid>
                    <Grid item xs={3}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Toevoeging</div>
                        <input type="text" className="form-control login-form-input" name="houseNumberAddition" value={houseNumberAddition} onChange={this.handleChange} autoComplete="off" />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={16}>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Postcode*</div>
                        <input type="text" className="form-control login-form-input" name="zipCode" value={zipCode} onChange={this.handleChange} autoComplete="off" />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Plaats*</div>
                        <input type="text" className="form-control login-form-input" name="city" value={city} onChange={this.handleChange} autoComplete="off" />
                      </div>

                    </Grid>
                  </Grid>
                  <div className="form-group">
                    <Button type="submit" variant="contained" color="primary" className="login-bttn" disabled={this.state.isLoading}> Aanmaken &nbsp;&nbsp; <ClipLoader sizeUnit={"px"} size={25} color={'white'} loading={this.state.isLoading} /></Button>
                  </div>    
                </Paper>
              </form>
            </Grid>
          </Grid>
        </div>
      </div>
      <div className="page-footer">
        <div className="page-footer-inner">
        </div>
      </div>
    </div>
  }
}

function mapStateToProps(state) {
  return {
    loggedInUser: state.login.user
  }
}


const connected = connect(mapStateToProps)(CreateCustomerComponent);
export { connected as CreateCustomerComponent };

