import { responseHandler } from 'helpers/responseHandler';
import * as api from 'helpers/api.js';
import { authHeader } from 'helpers/authHeader';
import { leadCustomerConstants } from './leadCustomer.Constants';

export function getSearchResults(data) {
    return dispatch => {
        var auth = authHeader();
        const requestOptions = {
            method: 'POST',
            headers: auth,
            body: JSON.stringify(data)
        };

        fetch(`${api.url()}Customer/SearchCustomer`, requestOptions).then(responseHandler)
            .then(data => {
                dispatch({ type: leadCustomerConstants.CUSTOMER_SEARCH_SUCCES, data });
            }).catch(function (error) {
                dispatch({ type: leadCustomerConstants.CUSTOMER_SEARCH_FAIL, error });
            });
    }
}

export function storeSelectedCustomer(customer) {
    return dispatch => {
        dispatch({ type: leadCustomerConstants.CUSTOMER_SELECTED_SUCCES, data: customer });
    }
}
