import { authHeader } from 'helpers/authHeader';
import * as api from 'helpers/api.js';
import { responseHandler } from 'helpers/responseHandler';
import { customerAddConstants } from './customeradd.Constants';
import { history } from 'helpers/history';
import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';

export function AddCustomer(data) {
  return dispatch => {
    var header = authHeader();

    const requestOptions = {
      method: 'POST',
      headers: header,
      body: JSON.stringify(data)
    };

    fetch(`${api.url()}Customer/SaveCustomer`, requestOptions).then(responseHandler)
      .then(data => {
        dispatch({ type: customerAddConstants.ADD_CUSTOMER_SUCCESS, data });
        history.replace('/createLead/houseType');
      }).catch(function (error) {
        alert("Er ging iets mis met opslaan van een klant");
        dispatch({ type: customerAddConstants.ADD_CUSTOMER_FAIL, error } );
      });
  }
}

export function loadPersonBegin() {
  var rows = [];

  let personBegins = [
    { id: 0, name: '' },
    { id: 1, name: 'Dhr.' },
    { id: 2, name: 'Mevr.' }
  ]

  for (var i = 0; i < personBegins.length; i++) {
    rows.push(
      <MenuItem key={i} value={personBegins[i].id}>{personBegins[i].name}</MenuItem>
    )
  }

  return rows;
}
