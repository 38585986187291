export function responseHandler(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        alert("Uw token is verlopen, log opnieuw in");
        return '';
      }
      alert('Er ging iets met het ophalen of opslaan van de data, refresh de pagina en probeer het nog een keer.');
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
