import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { HeaderComponent, TitleComponent } from '../../../components';
import { LeadUserComponent } from './leadUser/leadUser.Component';
import { LeadHousingPlanComponent } from './leadHousingPlan/leadHousingPlan.Component';
import Paper from '@material-ui/core/Paper';
import * as LeadAction from './leadOverview.Action';
import SignatureCanvas from 'react-signature-canvas';
import { ClipLoader } from 'react-spinners';
import './leadOverview.Style.css';

class LeadOverviewComponent extends Component {
  sigPad = {}

  constructor(props) {
    super(props);

    this.state = {
      trimmedDataURL: null,
      savingContract: false,
      savingLead: false,
      busySavingLead: false,
    }

    this.sendOrder = this.sendOrder.bind(this);
    this.handleInternalComment = this.handleInternalComment.bind(this);
    this.checkValidSelectedCalculation = this.checkValidSelectedCalculation.bind(this);
  }

  handleInternalComment(event) {
    const { dispatch } = this.props;
    dispatch(LeadAction.updateLeadComment(event.target.value));
  }

  componentDidMount(){
    if(this.state.busySavingLead || this.state.busySavingContractLead){
      this.setState({
        busySavingLead: false
      });
    }
  }

  setSelectedCalculation(lead, calculation) {
    if (calculation.selectedCalculation === "FoundationPlan"){
      lead.selectedCalculationType = calculation.foundationCalculationType;
      lead.selectedCalculationData = calculation.foundationCalculationData;
      lead.selectedCalculationInput = calculation.foundationCalculationInput;
    } else if(calculation.selectedCalculation === "PaymentPlan") {
      lead.selectedCalculationType = calculation.paymentPlanCalculationType;
      lead.selectedCalculationData = calculation.paymentPlanCalculationData;
      lead.selectedCalculationInput = calculation.paymentPlanCalculationInput;
    }
  }

  checkValidSelectedCalculation(calculation) {
    if (calculation.selectedCalculation === "FoundationPlan") {
      return calculation.isValid;
    } else if (calculation.selectedCalculation === "PaymentPlan") {
      return calculation.paymentFoundationIsValid;
    } else {
      return false;
    }
  }

  sendOrder(generateContract) {
    const {
      calculation,
      customer,
      housingPlanData,
      housingType,
      housingTypeData,
      leadOverview,
      photoData,
      repairs,
      userData,
      selectedImplementation
    } = this.props;

    var sigImage = this.checkValidSelectedCalculation(this.props.calculation) && this.sigPad.getTrimmedCanvas().toDataURL('image/png');

    var selectedHousingFaces = [];
    for(let i = 0; i < selectedImplementation.filteredHousingFaces.length; i++) {
      selectedHousingFaces.push(selectedImplementation.filteredHousingFaces[i].housingFace);
    }
    
    var leadData = {
      housingPlanData: housingPlanData.housingPlanData,
      housingType: housingType,
      customer: customer,
      housingTypeData: housingTypeData,
      organizationId: userData.organizationId,
      createdBy: userData.fullname,
      userId: userData.id,
      internalComment: leadOverview.comment,
      signatureDataUrl: generateContract ? sigImage : null,
      photos: photoData.photos,
      photoTempId: photoData.tempId,
      requiredRepairs: repairs,
      generateContract: generateContract,
      subscriptionStartYear: housingPlanData.housingPlanStart,
      selectedHousingTypeData: {
        housingFaces: selectedHousingFaces
      }
    }

    this.setSelectedCalculation(leadData, calculation);

    this.setState({
      savingContract: generateContract,
      savingLead: !generateContract,
      busySavingLead: true 
    });

    this.props.dispatch(LeadAction.saveLead(leadData, generateContract));
  }

  render() {
    const { leadOverview } = this.props;

    return (
      <div className="page-wrapper">
        <div className="page-header">
          <HeaderComponent />
        </div>
        <div className="page-content">
          <div className="page-title">
            <TitleComponent title={'Overzicht'} />
          </div>
          <div className="page-inner-content-default overview-component">
            <Grid container spacing={32}>
              {
                ((!this.props.calculation.isValid && this.props.calculation.selectedCalculation === "FoundationPlan")) || (!this.props.calculation.paymentFoundationIsValid && this.props.calculation.selectedCalculation === "PaymentPlan") && 
                <Grid item xs={12}>
                  <Paper className="paper-default invalid-message">
                    <div className="customer-information-inner">
                      <p>Bij deze calculatie komt het saldo op enig moment onder het maximaal toelaatbare negatieve saldo.<br />U kunt het onderhoudsplan wel opslaan, maar u dient daarna contact op te nemen met het hoofdkantoor voor akkoord.</p>
                    </div>
                  </Paper>
                </Grid>
              }
              <Grid item xs={6}> <LeadUserComponent /> </Grid>
              <Grid item xs={6}> <LeadHousingPlanComponent /> </Grid>
              <Grid item xs={12}>
                <Paper className="paper-default">
                  <div className="customer-information-inner">
                    <p className="information-title"> Commentaar </p>
                    <textarea className="md-textarea form-control" rows="5" onChange={this.handleInternalComment} value={leadOverview.comment}>

                    </textarea>
                  </div>
                </Paper>
              </Grid>
              {
                (this.checkValidSelectedCalculation(this.props.calculation)) &&
                <Grid item xs={12}>
                  <Paper className="paper-default">
                    <div className="customer-information-inner">
                      <p className="information-title">Handtekening</p>
                      <SignatureCanvas penColor='#004686' canvasProps={{ className: 'sigCanvas' }} ref={(ref) => this.sigPad = ref} />
                    </div>
                  </Paper>
                </Grid>
              }
              <Grid item xs={6}>
                <Button disabled={this.state.busySavingLead} size="large" className="bttn-save" onClick={() => this.sendOrder(false)}> Opslaan &nbsp;&nbsp; <ClipLoader sizeUnit={"px"} size={25} color={'white'} loading={this.state.savingLead} /> </Button>
              </Grid>
              <Grid item xs={6}>
                {
                  this.props.calculation.isValid && this.props.calculation.selectedCalculation === "FoundationPlan" && 
                  <Button disabled={this.state.busySavingLead} size="large" className="bttn-save" onClick={() => this.sendOrder(true)}> Contract aanmaken &nbsp;&nbsp; <ClipLoader sizeUnit={"px"} size={25} color={'white'} loading={this.state.savingContract} /> </Button>
                }
                {
                  this.props.calculation.paymentFoundationIsValid && this.props.calculation.selectedCalculation === "PaymentPlan" &&
                  <Button disabled={this.state.busySavingLead} size="large" className="bttn-save" onClick={() => this.sendOrder(true)}> Contract o.v. financiering aanmaken &nbsp;&nbsp; <ClipLoader sizeUnit={"px"} size={25} color={'white'} loading={this.state.savingContract} /> </Button>
                }
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="page-footer">
          <div className="page-footer-inner">
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const selectedHousingType = state.housingTypes.data.find(housingType => housingType.housingTypeId === state.housingTypes.selectedHousingTypeId);
  return {
    housingPlanData: state.housingPlanData,
    housingType: selectedHousingType,
    calculation: state.leadCalculation,
    customer: state.leadCustomers.selectedCustomer,
    userData: state.login.user,
    photoData: state.leadPhoto,
    repairs: state.leadConfirmation.repairs,
    leadOverview: state.leadOverview,
    selectedImplementation: state.selectedImplementation
  }
}

const connected = connect(mapStateToProps)(LeadOverviewComponent);
export { connected as LeadOverviewComponent };