import { authHeader } from 'helpers/authHeader';
import * as api from 'helpers/api.js';
import { responseHandler } from 'helpers/responseHandler';
import { loginConstants } from './login.Constants';
import { history } from 'helpers/history';

import packageJson from '../../../package.json';

export function login(email, password) {

  return dispatch => {
    var header = authHeader();

    const requestOptions = {
      method: 'POST',
      headers: header,
      body: JSON.stringify({ email, password })
    };

    fetch(`${api.url()}User/Login`, requestOptions).then(responseHandler)
      .then(data => {
        localStorage.setItem('user', JSON.stringify(data));
        localStorage.setItem("_expiredTime", Date.now());
        localStorage.setItem('version', packageJson.version)
        dispatch(success(data));
        history.push('/home');

      }).catch(function (error) {
        logout();
         dispatch(failure(error));
      });

    function success(user) { return { type: loginConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: loginConstants.LOGIN_FAIL, error } }
  }
}

export function checkLogin() {
  return dispatch => {
    var user = localStorage.getItem('user');
    if (user) {
      history.push('/home');
    }
  }
}

export function clearMessage() { 
  return dispatch => { 
    dispatch(resetMessage());
    
    function resetMessage() { return { type: loginConstants.RESET_MESSAGE } }
  }
}

export function logout() {
  return dispatch => {
    localStorage.removeItem('user');
    localStorage.removeItem('reduxState');

    history.push('/');
  }
}
