import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import * as LoginAction from './login.Action';

import './login.Style.css';

class LoginComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: ''
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.clearMessage = this.clearMessage.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(LoginAction.checkLogin());

    window.addEventListener('beforeunload', this.clearMessage);
  }

  clearMessage() {
    this.props.dispatch(LoginAction.clearMessage());
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email, password } = this.state;
    if (email && password) {
      this.props.dispatch(LoginAction.login(email, password));
    }
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  render() {
    const { email, password } = this.state;

    return (
      <div className="login-wrapper">
        <div className="login-content">
          <div className="login-inner-content">
            <Grid container justify="center" alignItems="center">
              <Grid item lg={6} md={8} xs={9}>
                <Paper>
                  <div className="login-content">
                    <img src={require('../../images/glansgarant.png')} className="logo-login" alt="login" />
                    <div className="login-title">Inloggen</div>
                    <form name="login-form" onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <div className="login-form-placeholer">Email</div>
                        <input className="form-control login-form-input" name="email" type="email" value={email} onChange={this.handleChange} autoComplete="off" nextfield="password" />
                      </div>
                      <div className="form-group password-form-group">
                        <div className="login-form-placeholer">Wachtwoord</div>
                        <input type="password" className="form-control login-form-input" name="password" value={password} onChange={this.handleChange} autoComplete="off" />
                      </div>
                      <div className="form-group">
                        <Button type="submit" variant="contained" color="primary" className="login-bttn"> Inloggen </Button>
                      </div>
                    </form>
                    <p className="login-error-message"> {this.props.message} </p>
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    message: state.login.message
  };
}

const connectedLoginComponent = connect(mapStateToProps)(LoginComponent);
export { connectedLoginComponent as LoginComponent };
