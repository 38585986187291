import { productConstants } from './product.Constants';

const initialState = {
    products: [],
    loaded: false,
    error: false
}

export const productReducer = (state, action) => {
    state = state || initialState;
   
    switch (action.type) {
        case productConstants.PRODUCT_LIST_SUCCESS: 
            return {
                products: action.data,
                loaded: true,
                error: false
            };
        case productConstants.PRODUCT_LIST_FAIL: 
            return {
                products: [],
                loaded: true,
                error: true
            };
        default:
            return state;
    }
}