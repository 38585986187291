import { responseHandler } from 'helpers/responseHandler';
import * as api from 'helpers/api.js';
import { productConstants } from './product.Constants';
import { authHeader } from 'helpers/authHeader';

export function listProducts(){
    return dispatch => {
        var auth = authHeader();

        const requestOptions = {
            method: 'GET',
            headers: auth
        }
        
        return fetch(`${api.url()}Product/GetAll`, requestOptions)
            .then(responseHandler)
            .then(data => {
                dispatch({ type: productConstants.PRODUCT_LIST_SUCCESS, data });
            })
            .catch(error => {
                dispatch({ type: productConstants.PRODUCT_LIST_FAIL, error });
            });
    }
}

export function reset() {
    return dispatch => {
        dispatch({ type: productConstants.PRODUCT_LIST_RESET });
    }
}