import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import * as EditHousingPlanAction from './editHousingPlan.Action';
import * as HousingPlanAction from '../leadHousingPlan.Action';
import * as HousingPlanCalculations from '../leadHousingPlanCalculation.Action';

class EditHousingPlanComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCells: []
        }

        this.handleSelectCell = this.handleSelectCell.bind(this);
        this.handleProductChange = this.handleProductChange.bind(this);
        this.handleSelectedYear = this.handleSelectedYear.bind(this);
    }

    // Wanneer een cell geselecteerd wordt, dan toevoegen aan de geselecteerde cells. 
    handleSelectCell(event, element) {
        var yearSelected = event.target.id;
        var cellState = this.state.selectedCells;

        // 1. Kijk in de state of er andere jaren inzitten, zoja? Dan state leeg maken en alleen dit jaar. 
        var elementsOtherYear = cellState.filter((item) => item.year !== yearSelected);

        if (elementsOtherYear.length > 0) {
            cellState.splice(elementsOtherYear, elementsOtherYear.length);
        }

        // 2. Kijk of cell al geselecteerd is, zoja? Dan weghalen. 
        var selectedCell = cellState.find((item) => item.housingElementId === element.housingElementId);

        if (selectedCell) {
            cellState.splice(selectedCell, 1);
        }
        else {
            var cell = {
                housingElementId: element.housingElementId,
                year: yearSelected
            }

            cellState.push(cell);
        }

        this.setState({
            selectedCells: cellState
        });
    }

    handleSelectedYear(event) {
        if (this.props.housingFaceSelectedData) {
            var elements = this.props.housingFaceSelectedData.elements;
            var yearSelected = event.target.id;

            var cells = [];
    
            for (var i = 0; i < elements.length; i++) {
                var cell = {
                    housingElementId: elements[i].housingElementId,
                    year: yearSelected
                }
                cells.push(cell);
            }
    
            this.setState({
                selectedCells: cells
            });
        }
    }

    handleProductChange(event) {
        var projectAbb = event.target.value;
        var year = event.target.name;

        var product = this.props.products.filter((e) => e.abbreviation === projectAbb);
        var yearPlanData = this.props.housingPlanData.filter((e) => e.year === parseInt(year));
        var selectedCells = this.state.selectedCells;

        EditHousingPlanAction.changehousingDataProducts(yearPlanData, selectedCells, this.props.housingFaceSelectedData, product[0]);

        // Erna de geselecteerde cells weer leegmaken. 
        this.setState({
            selectedCells: []
        });

        HousingPlanCalculations.calculatePrices(this.props.housingPlanData);
        this.props.dispatch(HousingPlanAction.storeHousingPlanData(this.props.housingPlanData));
    }

    render() {
        return (
            <div className="housingplan-inner">
                <Grid container>
                    <Grid item xs={1}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Jaar
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EditHousingPlanAction.loadYears(this.props.startYear, this.props.totalYears, this.handleSelectedYear)}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={9} className="housingface-element-innertable">
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {EditHousingPlanAction.leadHeaderColumns(this.props.housingFaceSelectedData.elements)}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EditHousingPlanAction.loadGrid(this.props.startYear, this.props.totalYears, this.props.housingFaceSelectedData.elements, this.props.housingPlanData, this.props.housingFaceSelectedData.housingFace, this.handleSelectCell, this.state.selectedCells)}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid item xs={2}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Wijzig product
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {EditHousingPlanAction.loadDropdowns(this.props.startYear, this.props.totalYears, this.props.products, this.handleProductChange)}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        housingTypeData: state.selectedImplementation.filteredHousingFaces,
        products: state.products.products,
        housingPlanData: state.housingPlanData.housingPlanData
    }
}

const connected = connect(mapStateToProps)(EditHousingPlanComponent);
export { connected as EditHousingPlanComponent };
