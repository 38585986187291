import { housingTypeConstants } from './housingType.Constants';

const initialState = {
    data: [],
    loaded: false,
    error: false,
    selectedHousingTypeId: 0
}

export const housingTypeReducer = (state, action) => {
  state = state || initialState;

  switch (action.type) {
    case housingTypeConstants.HOUSINGTYPE_GETLIST_SUCCESS:
      return {
          data: action.data,
          loaded: true,
          error: false
      };
    case housingTypeConstants.HOUSINGTYPE_GETLIST_FAIL:
      return {
          data: [],
          loaded: true,
          error: true,
          selectedHousingTypeId: 0
      }
    case housingTypeConstants.HOUSINGTYPE_SELECTED:
      return { 
        selectedHousingTypeId: action.housingTypeId,
        loaded: true,
        error: false,
        data: state.data
      }
    case housingTypeConstants.RESET_HOUSINGTYPES:
     return initialState;
    default:
      return state;
  }
}

