import { authHeader } from 'helpers/authHeader';
import * as api from 'helpers/api.js';
import { responseHandler } from 'helpers/responseHandler';
import { leadFinishConstants } from './leadFinish.Constants';

export function sendEmail(email, body, leadId) {
  return dispatch => {

    var header = authHeader();

    const requestOptions = {
      method: 'POST',
      headers: header,
      body: JSON.stringify({
        email: email,
        body: body,
        leadId: leadId
      })
    };

    return fetch(`${api.url()}Mail/SendEmailForFoundation`, requestOptions).then(responseHandler)
      .then(data => dispatch({ type: leadFinishConstants.MAIL_SUCCESS, data }))
      .catch(error => dispatch({ type: leadFinishConstants.MAIL_FAIL, error }));
  }
}
