import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import { TitleComponent, HeaderComponent } from 'components';
import PhotoPanel from './photoPanel/photoPanel.Component';

import './leadPhoto.Style.css';

class LeadPhotoComponent extends Component {

  handleNextButton() {
    history.push('/createLead/confirm');
  }

  render() {
    const hasOverviewPhoto = this.props.photos.find(photo => photo.category === 'Overzicht\/omgeving');
    const hasDetailPhoto = this.props.photos.find(photo => photo.category === 'Detailfoto\'s');
    return (
      <div className="page-wrapper">
        <div className="page-header">
          <HeaderComponent />
        </div>
        <div className="page-content">
          <div className="page-title">
            <TitleComponent title="Foto's" />
          </div>
          <div className="page-inner-content-default">
            <div className="photo-container">
              <PhotoPanel category="Overzicht/omgeving" />
              <PhotoPanel category="Detailfoto's" />
            </div>
          </div>
        </div>
        <div className="page-footer">
          <div className="page-footer-inner">
            <span className={hasOverviewPhoto && hasDetailPhoto ? "" : "hidden"} onClick={() => this.handleNextButton()}> <span className="next-button-text">Volgende</span> <img src={require('../../../images/next.svg')} className="next-button" alt="next" /> </span>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    photos: state.leadPhoto.photos
  }
}

export default connect(mapStateToProps)(LeadPhotoComponent);
