import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import './housingFaceResume.Style.css';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

class HousingFaceResumeComponent extends Component {
  render() {
    const hasFilledImplementations = (housingFace) => housingFace.housingElements.filter(element => findFilledImplementations(element).length > 0).length > 0;
    const findFilledImplementations = (element) => element.elementImplementations.filter((e) => e.count > 0);

    var list = [];

    for (var i = 0; i < this.props.housingFaces.length; i++) {
      var housingFace = this.props.housingFaces[i];

      if (hasFilledImplementations(housingFace)) {
        list.push(
          <TableRow className="housingFace">
            <TableCell>{housingFace.name}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell> </TableCell>
          </TableRow>
        )

        for (var j = 0; j < housingFace.housingElements.length; j++) {
          var element = housingFace.housingElements[j];
          var filledImplementations = findFilledImplementations(element);

          if (filledImplementations.length > 0) {
            list.push(
              <TableRow className="housingElement">
                <TableCell></TableCell>
                <TableCell>{element.housingElementName}</TableCell>
                <TableCell></TableCell>
                <TableCell> </TableCell>
              </TableRow>
            )

            for (var k = 0; k < filledImplementations.length; k++) {
              list.push(
                <TableRow className="implementation">
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>{filledImplementations[k].name}</TableCell>
                  <TableCell>{filledImplementations[k].count} stuk(s)</TableCell>
                </TableRow>
              )
            }
          }
        }
      }
    }

    return (
      <Paper className="resume-container">
        <p className="resume-title"> Samenvatting </p>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Gevel</TableCell>
              <TableCell>Element</TableCell>
              <TableCell>Implementatie</TableCell>
              <TableCell>Aantal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list}
          </TableBody>
        </Table>
      </Paper>
    )
  }
}

const connected = connect()(HousingFaceResumeComponent);
export { connected as HousingFaceResumeComponent };