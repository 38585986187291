import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import './implementation.Style.css';

export class Implementation extends Component {
  render() {
    var minClassName = this.props.count > 0 ? "button-min" : "button-min-disabled";

    return (
      <div>
        <Grid container>
          <Grid item xs={3}> <Button size="large" className={minClassName} onClick={() => this.props.handleMin(this.props.housingElementImplementationId)}> - </Button> </Grid>
          <Grid item xs={6}> <div className="count"> {this.props.count} </div>  </Grid>
          <Grid item xs={3}>
            <Button size="large" className="button-plus" onClick={() => this.props.handleAdd(this.props.housingElementImplementationId)}> + </Button>
            </Grid>
        </Grid>
      </div>
    )
  }
}


