import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

let appInsights = null;

const getInstrumentationKey = () => {
  if (window.location.hostname.includes("localhost") || window.location.hostname.includes("bit-ntb"))
    return '0a025164-ea0a-418c-9a01-fac602d9866e'; // development
  else if (window.location.hostname.includes("test"))
    return '0e30e5a7-3747-4a63-b5fb-cb709cc18b91'; // test
  else
    return '2e4f56c5-c006-494c-831a-0fb94704766a'; // production
}

const getAppInsights = () => {
  if (appInsights == null) {
    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: getInstrumentationKey(),
        maxBatchInterval: 0,
        disableFetchTracking: false
      }
    });

    appInsights.loadAppInsights();
  }

  return appInsights;
}

export const trackEvent = name => getAppInsights.trackEvent({ event: name });

export const trackPageView = (name, user) => {
  getAppInsights().trackPageView({ name: name, properties: { userName: user } });
}

export const trackTrace = (message, severityLevel, customProperties) => getAppInsights().trackTrace({ message: message, severityLevel: severityLevel, properties: customProperties });

export const trackException = error => getAppInsights().trackException({ error: error, severityLevel: SeverityLevel.Error });

//dependency: {
//  id: string;
//  name ?: string;
//  duration ?: number;
//  success ?: boolean;
//  responseCode: number;
//  correlationContext ?: string;
//  type ?: string;
//  data ?: string;
//  target ?: string;
//}
export const trackDependency = dependency => getAppInsights().trackDependencyData(dependency);

export { SeverityLevel } from '@microsoft/applicationinsights-web';
