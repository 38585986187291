import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import * as LeadCalculationAction from '../leadCalculation.Action';
import { trackTrace, SeverityLevel } from '../../../../helpers/TelemetryService';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import HelpIcon from '@material-ui/icons/Help';

import '../plan.Style.css';

const customStyles = {
  width: "100%",
  borderRadius: "0"
};

class FoundationPlanComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      months: props.leadFoundationCalculation && props.leadFoundationCalculation.foundationCalculationInput ? props.leadFoundationCalculation.foundationCalculationInput.months : 168,
      open: false
    }

    this.onChange = this.onChange.bind(this);
    this.changeMonths = this.changeMonths.bind(this);
  }

  onChange(event) {
    var newValue = "";
    if(this.props.leadFoundationCalculation && this.props.leadFoundationCalculation.foundationCalculationInput){
      newValue = this.state.months === "" ? this.props.leadFoundationCalculation.foundationCalculationInput.months : event.target.value;
    }
    
    if(newValue === ""){
      newValue = 1;
    }

    this.setState({
      months: newValue
    });

    this.changeMonths(newValue);
  }

  changeMonths(months) {
    if (months > 0 && months < 999) {
      this.props.dispatch(LeadCalculationAction.getFoundationCalculation(this.props.housingPlanData, months));
    }
  }

  render() {
    var monthPrice = 0;
    var lowestPrice = 0;
    var onceAmount = 0;
    var totalMonths = 0;
    var lowestBalanceYear = 0;
    var isValid = false;
    var hasCalculationData = false;

    if (this.props.leadFoundationCalculation != null) {
      var calculation = this.props.leadFoundationCalculation;

      if (calculation.foundationCalculationData && calculation.foundationCalculationData !== "" && calculation.foundationCalculationInput) {
        monthPrice = this.props.leadFoundationCalculation.foundationCalculationData.monthAmount.toFixed(2);
        lowestPrice = this.props.leadFoundationCalculation.foundationCalculationData.lowestBalanceYear.balance.toFixed(2);
        onceAmount = this.props.leadFoundationCalculation.foundationCalculationData.onceAmount.toFixed(2);
        totalMonths = this.props.leadFoundationCalculation.foundationCalculationInput.months;
        lowestBalanceYear = this.props.leadFoundationCalculation.foundationCalculationData.lowestBalanceYear.year;
        isValid = this.props.leadFoundationCalculation.isValid;

        hasCalculationData = true;
      }
    }

    if (!hasCalculationData) {
      trackTrace("No calculation data in foundationPlan.Component", SeverityLevel.Warning, { calculation: this.props.leadFoundationCalculation });
    }

    const getBalanceClass = (balance) => !isValid ? "tooNegativeBalance" : (balance < 0) ? "negativeBalance" : "positiveBalance";

    const handleTooltipClose = () => {
      this.setState({open: false});
    };
  
    const handleTooltipOpen = () => {
      this.setState({open: true});
    };      

    const tooltipError = <ClickAwayListener onClickAway={handleTooltipClose}>
    <div style={{ display: "inline"}}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={this.state.open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title="Bij deze calculatie komt het saldo op enig moment onder het maximaal toelaatbare negatieve saldo. U kunt het onderhoudsplan wel opslaan, maar u dient daarna contact op te nemen met het hoofdkantoor voor akkoord."
      >
        <HelpIcon onClick={handleTooltipOpen}>Click</HelpIcon>
      </Tooltip>
    </div>
  </ClickAwayListener>;
    return (
      <Grid item lg={4} md={4}>
        <Paper className="calculation-plan-paper">
          <div className="calculation-plan-header">
            <div className="calculation-plan-header-inner">
              {this.props.title}
              <p className="subtitle">Maandelijks abonnement</p>
            </div>
          </div>
          {
            hasCalculationData && <Grid container spacing={16}>
            <Grid item md={12} lg={12}>
              <div className="calculation-plan-body">
                <div className="calculation-plan-resume-body">
                  <Grid container spacing={16}>
                    <Grid item xs={6}> Aantal maanden  </Grid><Grid item xs={1}> : </Grid><Grid item xs={5}> {totalMonths}</Grid>
                    {
                      !isValid && 
                        <><Grid item xs={5}> Laagste saldo </Grid><Grid item xs={1}> : </Grid><Grid item xs={6} className={getBalanceClass(lowestPrice)}> {lowestBalanceYear} - &#8364; {lowestPrice}{tooltipError}</Grid></>
                    }
                    {
                      isValid && 
                        <><Grid item xs={5}> Laagste saldo  </Grid><Grid item xs={1}> : </Grid><Grid item xs={6} className={getBalanceClass(lowestPrice)}> {lowestBalanceYear} - &#8364; {lowestPrice}</Grid></>
                    }
                      <Grid item xs={6}> Eenmalig factureren </Grid><Grid item xs={1}> : </Grid><Grid item xs={5}> &#8364; {onceAmount}</Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            </Grid>
          }
          {
            !hasCalculationData && <Grid container spacing={16}>
            <Grid item md={12} lg={12}>
              <div className="calculation-plan-body">
                <div className="calculation-plan-resume-body">
                  Door een technische storing kon de berekening niet worden gemaakt. Probeer het later opnieuw.
                </div>
              </div>
            </Grid>
          </Grid>
          }
          {
            hasCalculationData && <Grid item md={12} lg={12}>
                <div className="month-duration-change">
                  <Grid container>
                    <Grid item xs={3}>
                        <Button size="large" style={customStyles} className="button-refresh housing-plan-add-bttn abbo-duration-button"><img src={require('../../../../images/calendar.svg')} alt="calendar"/></Button>
                    </Grid>
                    <Grid item xs={6}>
                      <input type="number" className="form-control refresh-txt" name="abboDuration" placeholder="Wijzig maanden" autoComplete="off" value={this.state.months} onChange={(e) => this.onChange(e)} />
                    </Grid>
                    <Grid item xs={3}>
                      <Button size="large" className="button-refresh housing-plan-add-bttn abbo-duration-button" onClick={() => this.changeMonths(this.state.months)}>  <img src={require('../../../../images/refresh.svg')} alt="refresh" /> </Button>
                    </Grid>
                  </Grid>
                </div>
                <div className="calculation-plan-price" onClick={() => this.props.handleCalculation(this.props.calculationName)} style={{ margin: "auto" }}>
                  &#8364; {monthPrice}
                  <p className="choose-txt"> Kies dit pakket </p>
                </div>
              </Grid>
          }
        </Paper>
      </Grid>
    )
  }
}

function mapStateToProps(state) {
  return {
    leadFoundationCalculation: state.leadCalculation,
    housingPlanData: state.housingPlanData
  }
}


const connected = connect(mapStateToProps)(FoundationPlanComponent);
export { connected as FoundationPlanComponent };
