import React, { Component } from 'react'
import './version.Style.css';

class VersionComponent extends Component {
  componentDidMount() {
    localStorage.setItem('version', process.env.REACT_APP_VERSION);
    localStorage.removeItem('user');
    localStorage.removeItem('reduxState');
    setTimeout(()=> {
      window.location.reload();
    }, 3000);
  }
  render() {

    return (
      <div className="page-wrapper">
        <div className="page-header"></div>
        <div className="page-content">
          <div className="page-title">
            <h1 className="home-title">Nieuwe versie</h1>
          </div>
          <div className="page-inner-content-home">
            <div className="page-inner-content-version">
              <p>
                U dient opnieuw in te loggen. Excuses voor het ongemak. 
              </p>
              <p>
                U wordt automatisch doorverwezen.
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default VersionComponent;
