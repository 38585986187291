import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { history } from 'helpers/history';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TitleComponent, HeaderComponent } from 'components';
import { HousingElementRowComponent } from './housingElementRow/housingElementRow.Component';
import { HousingFaceModal } from './housingFaceModal/housingFaceModal.Component';
import { HousingFaceResumeComponent } from './housingFaceResume/housingFaceResume.Component';
import * as HousingFaceAction from './housingFace.Action';
import QuestionBox from '../../../components/questionBox/questionBox.Component';

import './housingFace.Style.css';

class HousingFaceComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      faceIndex: 0,
      showResume: false,
      expanded: null,
      showBackModal: false
    }

    this.getSelectedFace = this.getSelectedFace.bind(this);
    this.handleFaceClick = this.handleFaceClick.bind(this);
    this.handleResumeClick = this.handleResumeClick.bind(this);
    this.handlePanel = this.handlePanel.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleBackQuestion = this.handleBackQuestion.bind(this);
    this.handleBackQuestionClose = this.handleBackQuestionClose.bind(this);
  }

  componentDidMount() {
    this.getSelectedFace(0);
  }

  getSelectedFace(index) {
    var selectedFace = this.props.housingFaces[index];

    if (selectedFace != null) {
      this.setState({
        faceIndex: index
      });
    }
  }

  handlePanel = panel => (event, expanded) => { 
    this.setState({
      expanded: expanded ? panel: false
    });
  }

  handleFaceClick(index) {
    this.setState({
      faceIndex: index,
      showResume: false,
      expanded: null
    });

    this.getSelectedFace(index);
  }

  handleResumeClick(event) {
    this.setState({
      showResume: true
    });
  }

  handleNext(e) {
    this.props.dispatch(HousingFaceAction.filterSelectedFaces(this.props.housingFaces));   
    history.push('/createLead/HousingPlan');
  }

  handleBackQuestion(e) {
    this.setState({showBackModal: true});
  }

  handleBack(e) {
    this.props.dispatch(HousingFaceAction.reset());
    history.goBack();
  }

  handleBackQuestionClose(e) {
    this.setState({showBackModal: false});
  }

  render() {
    const { expanded } = this.state;
    var summarySelectedClass = this.state.showResume ? "selection-face selected" : "selection-face notselected";

    return (
      <div className="page-wrapper" key={1}>
        <div className="page-header">
          <HeaderComponent />
        </div>
        <div className="page-content">
          <div className="page-title">
            <TitleComponent title={'Selecteer gevel informatie'} onClick={this.handleBackQuestion} />
          </div>
          <div className="page-inner-content-default">
          {
            this.state.showBackModal && 
            <>
              <QuestionBox 
                title="Weet u het zeker?"
                message="Weet u zeker dat u terug wilt? Uw wijzigingen worden niet opgeslagen!"
                onSubmit={this.handleBack}
                onCancel={this.handleBackQuestionClose} />
            </>
          }
            <Grid container justify="center" spacing={16} className="housing-face-grid">
              <Grid item xs={3}>
                <Paper className="paper-housingFace">
                  {
                    this.props.housingFaces.map((housingFace, i) => {
                      var result = FilledElements(housingFace);

                      if (i === this.state.faceIndex && !this.state.showResume) {
                        return <div key={i}>
                          <Grid container spacing={16} className="selection-face notselected">
                            <Grid item xs={3}>  <img src={require('../../../images/selected.svg')} alt="selected" />  </Grid>
                            <Grid item xs={7} onClick={(e) => this.handleFaceClick(i)}> {housingFace.name} </Grid>
                            <Grid item xs={2}> <HousingFaceModal housingFace={housingFace} />  </Grid> 
                          </Grid>
                        </div>
                      }
                      else if (result) {
                        return <div key={i}>
                          <div key={i}>
                            <Grid container spacing={16} className="selection-face">
                              <Grid item xs={3}> <img src={require('../../../images/success.svg')} alt="success" /> </Grid>
                              <Grid item xs={7} onClick={(e) => this.handleFaceClick(i)}> {housingFace.name} </Grid>
                              <Grid item xs={2}> <HousingFaceModal housingFace={housingFace} />   </Grid> 
                            </Grid>
                          </div>
                        </div>
                      }
                      else {
                        return <div key={i}>
                          <Grid container spacing={16} className="selection-face notselected">
                            <Grid item xs={3}>  <img src={require('../../../images/notfilled.svg')} alt="notfilled" /></Grid>
                            <Grid item xs={7} onClick={(e) => this.handleFaceClick(i)}> {housingFace.name} </Grid>
                            <Grid item xs={2}> <HousingFaceModal housingFace={housingFace} />  </Grid> 
                          </Grid>
                        </div>
                      }
                    })
                  }
                  {
                    this.state.showResume 
                      ?
                      <Grid container spacing={16} className={summarySelectedClass}>
                        <Grid item xs={3}><img src={require('../../../images/checklist-filled.svg')} alt="checked" /></Grid>
                        <Grid item xs={7} onClick={(e) => this.handleResumeClick(e)}> Samenvatting </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                      :
                      <Grid container spacing={16} className={summarySelectedClass}>
                        <Grid item xs={3}><img src={require('../../../images/checklist.svg')} alt="checked" /></Grid>
                        <Grid item xs={7} onClick={(e) => this.handleResumeClick(e)}> Samenvatting </Grid>
                        <Grid item xs={2}></Grid>
                      </Grid>
                  }
                </Paper>
              </Grid>
              <Grid item xs={9} className="grid-housingFace-content"> 
                {
                  this.state.showResume
                    ?
                      <HousingFaceResumeComponent housingFaces={this.props.housingFaces} />
                    :
                    <div>
                      {
                        this.props.housingFaces[this.state.faceIndex] ? this.props.housingFaces[this.state.faceIndex].housingElements.map((housingElement, i) => {
                          var elementsFilled = housingElement.elementImplementations.filter((e) => e.count > 0);

                          var filled = '';
                          if(elementsFilled.length > 0)
                          {
                            filled = <img src={require('../../../images/success_small.svg')} alt="success-small" />;
                          }

                          return <ExpansionPanel key={i} expanded={expanded === i} onChange={this.handlePanel(i)} className="paper-housingFace">
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                              <Typography className="typography-heading">{housingElement.housingElementName}</Typography>
                              <Typography className="typography-secundary"> {filled} </Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <HousingElementRowComponent key={i} elementIndex={i} housingElement={housingElement} elementImplementations={housingElement.elementImplementations} />
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                        }) : ""
                      }
                    </div>
                }
              </Grid>
            </Grid>
          </div>
        </div> 
        <div className="page-footer">
        <div className="page-footer-inner"> 
          <span onClick={() => this.handleNext()}> <span className="next-button-text">Volgende</span> <img src={require('../../../images/next.svg')} className="next-button" alt="next"  /> </span> 
         </div> 
         </div> 
      </div>
    )
  }
}

// Kijk of in de gevel een bepaald element is ingevuld. Is dat al ingevuld? Dan moet vinkje groen. 
function FilledElements(housingFace) {
  if (housingFace != null) {
    for (var i = 0; i < housingFace.housingElements.length; i++) {
      var housingElement = housingFace.housingElements[i];

      for(let j = 0; j < housingElement.elementImplementations.length; j++) {
        if (housingElement.elementImplementations[j].count > 0) {
          return true;
        }
      }
    }
  }

  return false;
}

function mapStateToProps(state) {
  var selectedHousingType = state.housingTypes.data.find((housingType) => housingType.housingTypeId === state.housingTypes.selectedHousingTypeId);

  return {
    selectedImplementations: state.selectedImplementation.selectedElements,
    housingFaces: selectedHousingType ? selectedHousingType.housingFaces : []
  }
}

const connected = connect(mapStateToProps)(HousingFaceComponent);
export { connected as HousingFaceComponent };


