export const LeadCalculationConstants = { 
    REQUEST_FOUNDATION_CALCULATION_SUCCESS: 'REQUEST_FOUNDATION_CALCULATION_SUCCESS',
    REQUEST_FOUNDATION_CALCULATION_FAIL: 'REQUEST_FOUNDATION_CALCULATION_FAIL',

    REQUEST_PAYMENTPLAN_CALCULATION_SUCCESS: 'REQUEST_PAYMENTPLAN_CALCULATION_SUCCESS',
    REQUEST_PAYMENYPLAN_CALCULATION_FAIL: 'REQUEST_PAYMENYPLAN_CALCULATION_FAIL',

    RESET_LEADCALCULATIONS: 'RESET_LEADCALCULATIONS',

    SELECTED_CALCULATION: 'SELECTED_CALCULATION'
}