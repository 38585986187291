import React, { Component } from 'react';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import * as HousingFaceAction from '../housingFace.Action';
import { Implementation } from './implementation/implementation.Component';

import './housingElementRow.Style.css';

class HousingElementRowComponent extends Component {
  Add = (housingElementImplementationId) => {
    if (this.props.elementImplementations) {
      var elementImplementation = this.props.elementImplementations.find((e => e.housingElementImplementationId === housingElementImplementationId));
      if(elementImplementation)
      {
        elementImplementation.count = ++elementImplementation.count;
        this.props.dispatch(HousingFaceAction.updateImplementationCount(elementImplementation));
      }
    }
  }

  Min = (housingElementImplementationId) => {
    if (this.props.elementImplementations) {
      var elementImplementation = this.props.elementImplementations.find((e => e.housingElementImplementationId === housingElementImplementationId));
      if(elementImplementation && elementImplementation.count > 0)
      {
        elementImplementation.count = --elementImplementation.count;
        this.props.dispatch(HousingFaceAction.updateImplementationCount(elementImplementation));
      }
    }
  }

  render() {
    var housingElement = this.props.housingElement;
    var implementations = housingElement.elementImplementations.map((implementation, index) => {
      return (
        <Grid item xs={12} key={index}>
          <Grid container>
            <Grid item xs={8}>
              {implementation.name} <span className="implementation-price"> &#8364;{implementation.price} </span>
            </Grid>
            <Grid item xs={4}>
              <Implementation housingElementImplementationId={implementation.housingElementImplementationId} count={implementation.count} handleAdd={this.Add.bind(this)} handleMin={this.Min.bind(this)} />
            </Grid>
          </Grid>
        </Grid>
      );
    });

    return (
      <div className="housing-element-row">
        <Grid container spacing={16}>
          {
            implementations.length > 0 ?  implementations : <div className="housing-element-message"> Bij dit element zijn geen uitvoeringen gevonden. </div> 
          }
        </Grid>
      </div>
    )
  }
}

const connectedHousingElementRowComponent = connect()(HousingElementRowComponent);
export { connectedHousingElementRowComponent as HousingElementRowComponent };

