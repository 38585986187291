import { responseHandler } from 'helpers/responseHandler';
import * as api from 'helpers/api.js';
import { authHeader } from 'helpers/authHeader';
import { history } from 'helpers/history';
import { leadOverviewConstants } from './leadOverview.Constants';
import { housingTypeConstants } from '../housingType/housingType.Constants';
import { LeadCalculationConstants } from '../leadCalculation/leadCalculation.Constants';
import { leadCustomerConstants } from '../leadCustomer/leadCustomer.Constants';
import { leadHousingPlanConstants } from '../leadHousingPlan/leadHousingPlan.Constants';
import { photoPanelConstants } from '../leadPhoto/photoPanel/photoPanel.Constants';
import { resetConfirmations } from '../leadConfirm/leadConfirm.Action';
import { headerConstants } from '../../../components/layout/header/header.Constants';

export function saveLead(data, generateContract) {
  return dispatch => {
    var auth = authHeader();

    const requestOptions = {
      method: 'POST',
      headers: auth,
      body: JSON.stringify(data)
    };

    fetch(`${api.url()}Lead/SaveLead`, requestOptions).then(responseHandler)
      .then(response => {
        // Code 100 = FoundationPlan, 200 = PaymentPlan
        response.selectedCalculationType = data.selectedCalculationType.code;
        response.email = data.customer.email;
        response.signed = generateContract && data.signatureDataUrl !== undefined;

        dispatch(success(response));
        dispatch(resetLead());

        history.push('/createLead/finish');

      }).catch(function (error) {
        dispatch(failure(error));
      });

    function success(data) { return { type: leadOverviewConstants.ADD_LEAD_SUCCESS, data } }
    function failure(error) { return { type: leadOverviewConstants.ADD_LEAD_FAIL, error } }
  }
}

export function updateLeadComment(comment) {
  return dispatch => 
    dispatch({
      type: leadOverviewConstants.UPDATE_LEADCOMMENT,
      data: comment
    });
}

export function resetLead() {
  return dispatch => {
    // Clean up some of the global state.
    dispatch({ type: housingTypeConstants.RESET_HOUSINGTYPES });
    dispatch({ type: LeadCalculationConstants.RESET_LEADCALCULATIONS });
    dispatch({ type: leadCustomerConstants.RESET_LEADCUSTOMER });
    dispatch({ type: leadHousingPlanConstants.RESET_LEADHOUSINGPLAN });
    dispatch({ type: leadHousingPlanConstants.RESET_LEADHOUSINGPLAN_PRODUCTS });
    dispatch({ type: photoPanelConstants.RESET_PHOTOS });

    dispatch(resetConfirmations());
  }
}

export function resetWholeState() {
  return dispatch => {
    dispatch({ type: headerConstants.HARD_RESET});
  }
}
