import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';

// Bij het wijzigen van een product bij een gevel of element. 
export function changehousingDataProducts(yearPlanData, selectedCells, selectedHousingFace, product) {
  if (yearPlanData.length > 0) {
    for (var i = 0; i < yearPlanData[0].housingFaceData.length; i++) {
      var housingFace = yearPlanData[0].housingFaceData[i];
    
      if (housingFace.housingFaceId === selectedHousingFace.housingFace.housingFaceId) {
        // Elementen wijzigen die hierin zitten EN geselecteerd zijn. 

        for (var j = 0; j < housingFace.housingFaceElements.length; j++) {
          var housingElement = housingFace.housingFaceElements[j];

          for (var k = 0; k < selectedCells.length; k++) {
            var cell = selectedCells[k];

            if (housingElement.housingElementId === cell.housingElementId) {
              housingElement.housingElementSelectedProduct = product.abbreviation;
              housingElement.housingElementSelectedProductFactor = product.factor;
              housingElement.housingElementSelectedProductMinAmount = product.minAmount;
            }
          }
        }
      }
    }
  }
}

// Alle elementen in de bestek overzicht worden horizontaal getoond in de header.
export function leadHeaderColumns(elements) {
  var columns = [];

  for (var i = 0; i < elements.length; i++) {
    var element = elements[i];

    if (element) {
      columns.push(
        <TableCell key={i} className="edithousingplan-table">{elements[i].housingElementName}</TableCell>
      )
    }
  }
  return columns;
}

export function loadDropdowns(startYear, totalYears, products, handleYearDropdown) {
  var rows = [];

  var year = startYear;
  for (var i = 0; i < totalYears; i++) {
    rows.push(
      <TableRow key={i} className="houseplan-edit-rowheight">
        <TableCell>
          <Select
            input={
              <OutlinedInput
                name={"" + year}
                id="outlined-age-simple"
                value=""
                labelWidth={150}
              />
            }
            onChange={((val) => handleYearDropdown(val))}
          >
            <MenuItem key="-1" value="">
              <em>None</em>
            </MenuItem>
            {
              products.map((product, i) => (
                <MenuItem key={i} value={product.abbreviation}>{product.abbreviation}</MenuItem>
              ))
            }
          </Select>
        </TableCell>
      </TableRow>
    )
    year++;
  }

  return rows;
}

export function loadYears(startYear, totalYears, yearHandler) {
  var rows = [];

  var year = startYear;
  for (var i = 0; i < totalYears; i++) {
    rows.push(
      <TableRow key={i} className="houseplan-edit-rowheight">
        <TableCell className="year-cell" id={year} onClick={(e) => yearHandler(e)}>
          {year}
        </TableCell>
      </TableRow>
    )
    year++;
  }

  return rows;
}

export function loadGrid(startYear, totalYears, elements, housingPlanData, selectedHousingFace, handleSelectCell, selectedCells) {
  var rows = [];
  var year = 0;

  const findHousingPlanElement = (year, housingElementId) => {    
    var selectedHousingPlanYear = housingPlanData.find((e) => e.year === year);
    if(selectedHousingPlanYear) {
      var selectedHousingFaceData = selectedHousingPlanYear.housingFaceData.find((e) => e.housingFaceId === selectedHousingFace.housingFaceId);
      return selectedHousingFaceData.housingFaceElements.find((e) => e.housingElementId === housingElementId);
    }
    return selectedHousingFaceData;
  }
    
  const isSelected = (year, housingElementId) => selectedCells.filter(cell => cell.year === year.toString() && cell.housingElementId === housingElementId).length > 0;

  const createCell = (item, i) => {
    var housingPlanElement = findHousingPlanElement(startYear + year, item.housingElementId);
    if(housingPlanElement) {
      return <TableCell key={i} id={startYear + year} className={`${(isSelected(startYear + year, item.housingElementId) ? 'selected-Cell' : '')} edithousingplan-table`} onClick={(e) => handleSelectCell(e, item)}>
      {housingPlanElement.housingElementSelectedProduct}
    </TableCell>
    }
    return <></>;
  };

  for (year = 0; year < totalYears; year++) {
    rows.push(
      <TableRow key={year} className="houseplan-edit-rowheight">
        {
          elements.map(createCell)
        }
      </TableRow>
    )
  }

  return rows;
}
