import { housingFaceConstants } from './housingFace.Constants';

const initialState = {
  selectedElements: {},
  filteredHousingFaces: []
}

export const housingFaceReducer = (state, action) => {
  state = state || initialState;
  switch (action.type) {
    case housingFaceConstants.IMPLEMENTATION_UPDATE_COUNT:
      var selectedElements = state.selectedElements;
      if (action.implementation.count === 0){
        delete selectedElements[action.implementation.housingElementImplementationId]
      } else {
        selectedElements[action.implementation.housingElementImplementationId] = action.implementation.count
      }
      return {
        ...state,
        selectedElements: {
          ...selectedElements
        }
      }
    case housingFaceConstants.HOUSINGFACE_RESET:
      return {
        ...initialState
      }
    case housingFaceConstants.HOUSINGTYPE_FILTERDATA:
      return {
        ...state,
        filteredHousingFaces: action.copy
      }
    default:
      return state; 
  }
}
