import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import Grid from '@material-ui/core/Grid';
import { TitleComponent, HeaderComponent } from 'components';
import { FoundationPlanComponent } from './foundationPlan/foundationPlan.Component';
import { PaymentPlanComponent } from './paymentPlan/paymentPlan.Component';
import * as LeadCalculationAction from './leadCalculation.Action';

import LoadingOverlay from 'react-loading-overlay';

import './leadCalculation.Style.css';

class LeadCalculationComponent extends Component {
  constructor(props) { 
    super(props);

    this.handleCalculation = this.handleCalculation.bind(this);
  }

  handleNextButton() {
    history.push('/createLead/photo');
  }

  componentDidMount() {
    this.resetCalculations();
    this.getFoundationCalculation();
    this.getPaymentPlanCalculation();
  }

  resetCalculations() {
    this.props.dispatch(LeadCalculationAction.resetCalculations());
  }

  getFoundationCalculation() {
    const { calculation } = this.props;
    var months = calculation && calculation.foundationCalculationInput ? calculation.foundationCalculationInput.months : 0;
    this.props.dispatch(LeadCalculationAction.getFoundationCalculation(this.props.housingPlanData, months));
  }

  getPaymentPlanCalculation() {
    const { calculation } = this.props;
    var months = calculation && calculation.paymentPlanCalculationInput ? calculation.paymentPlanCalculationInput.months : 0;
    var amountFinance = calculation && calculation.paymentPlanCalculationInput ? calculation.paymentPlanCalculationInput.amountFinance: 0;
    this.props.dispatch(LeadCalculationAction.getPaymentPlanCalculation(this.props.housingPlanData, months, amountFinance));
  }

  handleCalculation(data) {
    this.props.dispatch(LeadCalculationAction.setSelectedCalculationPlan(data));
    history.push('/createLead/overview');
  }

  render() {
    return (
      <div className="page-wrapper">
        <div className="page-header">
          <HeaderComponent />
        </div>
        <LoadingOverlay active={!this.props.calculation.foundationIsLoaded || !this.props.calculation.paymentIsLoaded} spinner text='Laden...' className="loader-overlay">
        <div className="page-content">
          <div className="page-title">
            <TitleComponent title={'Calculaties'} />
          </div>
          <div className="page-inner-content-lead-calculation">
            <Grid container spacing={16}>
              {
                this.props.calculation.foundationIsLoaded && 
                  <FoundationPlanComponent title={'Stichting Beheer'} handleCalculation={this.handleCalculation} calculationName={'FoundationPlan'} />   
              }                         
              {
                this.props.calculation.paymentIsLoaded &&
                  <PaymentPlanComponent title={'Betaalplan'} handleCalculation={this.handleCalculation} calculationName={'PaymentPlan'} />    
              }
            </Grid>
          </div>
        </div>
        <div className="page-footer">
          <div className="page-footer-inner"></div> 
        </div>
        </LoadingOverlay>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    housingPlanData: state.housingPlanData,
    calculation: state.leadCalculation
  }
}

const connectedLeadCalculationComponent = connect(mapStateToProps)(LeadCalculationComponent);
export { connectedLeadCalculationComponent as LeadCalculationComponent };
