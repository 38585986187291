import { leadHousingPlanConstants } from './leadHousingPlan.Constants';

const initialState = { 
  housingPlanData: [],
  housingPlanDurage: 15,
  housingPlanStart: new Date().getFullYear(),
}

export const housingPlanReducer = (state, action) => { 
  state = state || initialState;

  switch(action.type)  {
    case leadHousingPlanConstants.OVERVIEWHOUSINGPLAN_REQUEST: 
      return {
        housingPlanData: [],
      }
    case leadHousingPlanConstants.STORE_HOUSINGPLANDATA: 
      return {
        ...state,
        housingPlanData: action.data,
      }
    case leadHousingPlanConstants.HOUSING_PLAN_ABBODURAGE: 
      return { 
        ...state,
        housingPlanDurage: action.data,
      }
    case leadHousingPlanConstants.HOUSING_PLAN_STARTYEAR: 
      return { 
        ...state, 
        housingPlanStart: action.data,
      }
    case leadHousingPlanConstants.RESET_LEADHOUSINGPLAN: 
      return {
        ...initialState
      };
    default: 
      return state
  }
}
