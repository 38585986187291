import React from 'react';
import './questionBox.Style.css';
import Modal from 'react-modal';
import Button from '@material-ui/core/Button';

const QuestionBox = props => {
  const {
    title,
    message,
    items,
    onSubmit,
    onCancel
  } = props;

  // To override react-bootstrap modal component
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      padding: '0 !important',
      marginRight: '-50%',
      transform: 'translate(50%, 50%)',
      width: '50vw',
      height: '50vh',
      border: '0 !important',
      backgroundColor: 'white'
    }
  };

  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      isOpen={true}
      className="questionBox"
      style={customStyles}>
           <div className="modal-wrapper">
        <div className="modal-header">
          <div className="modal-header-inner">
            <p className="modal-title">{title}</p>
          </div>
        </div>
        <div className="modal-content">
          <div className="modal-inner-content">
            {message}
            {
              (items && items.length > 0) &&
                <div>
                  <ul>
                    {
                      items.map(item => <li>{item}</li>)
                    }
                  </ul>
                </div>
            }
          </div>
        </div>
        <div className="modal-footer">
          {
            onSubmit && 
              <Button onClick={onSubmit}>Verder gaan</Button>
          }
          <Button onClick={onCancel}>Annuleren</Button>
        </div>
      </div>
    </Modal>
  );
}

export default QuestionBox;