import { loginConstants } from './login.Constants';

const initialState = {
  user: '',
  message: '',
  loggedIn: false
}

export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        message: '',
        user: action.user
      };
    case loginConstants.LOGIN_FAIL:
      return {
        loggedIn: false,
        message: 'Er ging iets mis tijdens het inloggen: ' + action.error.toString()
      };
    case loginConstants.RESET_MESSAGE: 
      return { 
        ...state,
        message: ''
      }
    default:
      return state; 
  }
}
