import { housingFaceConstants } from './housingFace.Constants';

export function filterSelectedFaces(housingFaces) {
  return dispatch => {
    var copy = [];

    for (var i = 0; i < housingFaces.length; i++) {
      var housingFace = housingFaces[i];

      var elements = [];

      for (var j = 0; j < housingFace.housingElements.length; j++) {

        if (housingFace.housingElements[j].elementImplementations.length > 0) {
          var housingElements = housingFace.housingElements[j].elementImplementations.filter((e) => e.count > 0);

          if (housingElements.length > 0) {
            elements.push(housingFace.housingElements[j]);
          }
        }
      }

      if(elements.length > 0)
      {
        var newElement = { 
          housingFace: housingFace,
          elements: elements
        }

        copy.push(newElement);
      }
    }

    dispatch({ type: housingFaceConstants.HOUSINGTYPE_FILTERDATA, copy });
  }
}

export function updateImplementationCount(implementation) {
  return dispatch => {
    dispatch({ type: housingFaceConstants.IMPLEMENTATION_UPDATE_COUNT, implementation});
  }
}

export function reset(){
  return dispatch => {
    dispatch({ type: housingFaceConstants.RESET});
  }
}