export const housingFaceConstants = {
  ELEMENTIMPLEMENTATION_CLEARLIST: 'ELEMENTIMPLEMENTATION_CLEARLIST',

  HOUSINGTYPE_FILTERDATA: 'HOUSINGTYPE_FILTERDATA',

  HOUSINGTYPEDATA_UPDATELIST: 'HOUSINGTYPEDATA_CHANGE',

  HOUSINGTYPE_CHANGED: 'HOUSINGTYPE_CHANGED',

  IMPLEMENTATION_UPDATE_COUNT: 'IMPLEMENTATION_UPDATE_COUNT',
 
  HOUSINGFACE_UPDATEACCESSIBILITY: 'HOUSINGFACE_UPDATEACCESSIBILITY',

  RESET :'RESET'
}
