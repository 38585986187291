import React, { Component } from 'react';
import { Grid, Paper } from '@material-ui/core';
import * as api from 'helpers/api.js';
import ImageUploader from 'react-images-upload';
import Resizer from "react-image-file-resizer";
import { connect } from 'react-redux';
import { addPhoto, updatePhotoDescription, deletePhoto, resetPhotos } from './photoPanel.Action';
import './photoPanel.Style.css';
import QuestionBox from '../../../../components/questionBox/questionBox.Component';

class PhotoPanelPhotoComponent extends Component {
  constructor(props) {
    super(props);
    this.onDrop = this.onDrop.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.updateDescription = this.updateDescription.bind(this);
    this.delete = this.delete.bind(this);
    this.handleErrorClose = this.handleErrorClose.bind(this);

    this.state = {
      uploadKey: 1,
      showErrorMessage: false
    };
  }

  uploadFile(file) {
    const { leadPhoto, category, resetPhotos, addPhoto } = this.props;
    const { uploadKey } = this.state;

    if (!leadPhoto.tempId) {
      resetPhotos();
    }

    var fileName = file.name;
    if (fileName === "image.jpg") {
      // photo taken by camera on IOS is always named "image.jpg"
      // rename the file so we can take more than one photo
      var index = 1;
      const checkName = photo => photo.name === `camera_${index}.jpg`;
      while (leadPhoto.photos.find(checkName)) {
        index++;
      }

      fileName = `camera_${index}.jpg`;
    }

    if (!leadPhoto.photos.find(photo => photo.name === fileName)) {
      Resizer.imageFileResizer(
        file,   // the file from input
        1024,   // width
        1024,   // height
        "JPEG", // compress format WEBP, JPEG, PNG
        70,     // quality
        0,      // rotation
        blob => {
          const image = blob.size < file.size ? blob : file;

          let formData = new FormData();
          formData.append("name", fileName);  // we cannot change the name of the file object, so we send the name we want to use in an extra form field
          formData.append("file", image);

          const user = JSON.parse(localStorage.getItem('user'));
          const headers = {
            'Authorization': 'Bearer ' + user.token,
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
            'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
          };

          const requestOptions = {
            method: 'POST',
            headers: headers,
            body: formData
          };

          fetch(`${api.url()}Photo/UploadPhoto/${leadPhoto.tempId}`, requestOptions).then(response => {
            if (response.ok) {
              return response.blob();
            }
          }).then(result => {
            const photo = {
              name: fileName,
              category: category,
              contentType: file.type,
              data: window.URL.createObjectURL(result)
            };

            addPhoto(photo);
          }).catch(() => {
            this.setState({
              showErrorMessage: true
            });
          });
        },
        "blob" // blob or base64 default base64
      );
    }

    this.setState({ uploadKey: uploadKey + 1 });
  }

  onDrop(photos) {
    [].forEach.call(photos, this.uploadFile);
  }

  updateDescription(e, photo) {
    const { updatePhotoDescription } = this.props;

    photo.description = e.target.value;
    updatePhotoDescription(photo);
  }

  delete(photo) {
    const { deletePhoto } = this.props;

    window.URL.revokeObjectURL(photo.data);
    deletePhoto(photo);
  }

  handleErrorClose(){
    this.setState({
      showErrorMessage: false
    });
  }

  render() {
    const { category, leadPhoto } = this.props;
    const { uploadKey } = this.state;

    return (
      <Paper className="photo-paper">
        {
          this.state.showErrorMessage &&
            <QuestionBox onCancel={this.handleErrorClose} message={`Er is een probleem met de verbinding. Kon de foto niet verzenden naar de server. Probeer het a.u.b. opnieuw of met een andere foto.`} title="Verbindingsprobleem"/>
        }
        <div className="photo-header">{category}</div>
        <Grid container className="photo-grid">
          <Grid item xs={6} sm={4} md={3} lg={2} className="photo-item add-button">
            <ImageUploader
              key={`imageUploader-${category}-${uploadKey}`}
              withIcon={true}
              buttonText='Foto toevoegen'
              onChange={this.onDrop}
              imgExtension={['.jpg', '.gif', '.png', 'jpeg']}
              maxFileSize={15000000}
              label=""
            />
          </Grid>
          {leadPhoto && leadPhoto.photos.map(photo =>
            photo.category === category &&
            <Grid item xs={6} sm={4} md={3} lg={2} className="photo-item" key={`photo-${photo.category}-{${photo.name}`}>
              <div className="photo-image-container">
                {photo.data && <img src={photo.data} alt={photo.name} className="photo-image" />}
              </div>
              <div className="photo-name">
                {photo.name}
              </div>
              <div className="photo-description">
                <input type="text" className="form-control refresh-txt" placeholder="Omschrijving" autoComplete="off" value={photo.description} onChange={e => this.updateDescription(e, photo)} />
              </div>
              <div className="photo-delete-button" onClick={() => this.delete(photo)}></div>
            </Grid>
          )}
        </Grid>
      </Paper>
    )
  }
}

const mapStateToProps = state => ({
    leadPhoto: state.leadPhoto,
})

const mapDispatchToProps = {
  addPhoto,
  deletePhoto,
  resetPhotos,
  updatePhotoDescription
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoPanelPhotoComponent);
