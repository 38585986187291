import React, { Component } from 'react';
import './header.Style.css';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { history } from 'helpers/history';
import * as HeaderAction from './header.Action';

class HeaderComponent extends Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  logout() {
    this.props.dispatch(HeaderAction.logout());
  }

  home() {
    history.push('/home');
  }

  render() {
    return (
      <div className="app-header">
        <div className="header-content">
          <Grid container className="header-inner-content"  direction="row" alignItems="center">
            <Grid item xs={1}>
            <img src={require('../../../images/home.svg')} onClick={this.home} alt="home" />
            </Grid>
            <Grid item xs={8}>
              <img src={require('../../../images/glansgarant.png')} className="logo-header" alt="logo" />
            </Grid>
            <Grid item xs={2}>
              <div onClick={this.logout} className="user-container">  <img src={require('../../../images/user.svg')} alt="user" /> <span className="user-name"> {this.props.user.fullname}</span> </div>
            </Grid>
            <Grid item xs={1}>
              <img src={require('../../../images/logout.svg')} className="menu" onClick={this.logout} alt="logout" />
            </Grid>
          </Grid>
        </div>

      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    user: state.login.user,
  };
}

const connected = connect(mapStateToProps)(HeaderComponent);
export { connected as HeaderComponent };
