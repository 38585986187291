import React, { Component } from 'react';
import { connect } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import './leadUser.Style.css';

class LeadUserComponent extends Component {

    render() {
        return <div>
            <Paper className="paper-default">
                <div className="customer-information-inner">
                    <p className="information-title"> Persoonlijke gegevens </p>
                    
                    <Table className="customer-information-table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell className="customer-information-title">Naam</TableCell>
                                <TableCell> {this.props.customer.name} </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell  className="customer-information-title">Adres</TableCell>
                                <TableCell> {this.props.customer.street} {this.props.customer.houseNumber} {this.props.customer.houseNumberAddition} </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell  className="customer-information-title">Postcode/ Plaats</TableCell>
                                <TableCell> {this.props.customer.zipCode} {this.props.customer.city} </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell  className="customer-information-title">Soort woning</TableCell>
                                <TableCell> {this.props.selectedHousingTypeName}  </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell  className="customer-information-title">Email</TableCell>
                                <TableCell> {this.props.customer.email}  </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell  className="customer-information-title">Telefoonnummer</TableCell>
                                <TableCell> {this.props.customer.phoneLine}  </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </Paper>
        </div>
    }
}

function mapStateToProps(state) {
    const selectedHousingType = state.housingTypes.data.find(housingType => housingType.housingTypeId === state.housingTypes.selectedHousingTypeId);
    return {
        selectedHousingTypeName: selectedHousingType ? selectedHousingType.name : "onbekend",
        customer: state.leadCustomers.selectedCustomer,
    }
}

const connected = connect(mapStateToProps)(LeadUserComponent);
export { connected as LeadUserComponent };
