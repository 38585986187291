import { photoPanelConstants } from './photoPanel.Constants';

export const addPhoto = photo => ({
  type: photoPanelConstants.ADD_PHOTO,
  photo: photo
})

export const deletePhoto = photo => ({
  type: photoPanelConstants.DELETE_PHOTO,
  photo: photo
})

export const updatePhotoDescription = photo => ({
  type: photoPanelConstants.UPDATE_PHOTO_DESCRIPTION,
  photo: photo
})

export const resetPhotos = () => ({
  type: photoPanelConstants.RESET_PHOTOS
})
