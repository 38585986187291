import React, { Component } from 'react';
import { connect } from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import MenuItem from '@material-ui/core/MenuItem';

import * as HousingPlanAction from '../leadHousingPlan.Action';
import * as HousingPlanCalculationAction from '../leadHousingPlanCalculation.Action';
import * as HousingPlanOverviewAction from './overviewHousingPlan.Action';

import './overviewHousingPlan.Style.css';

class OverviewHousingPlanComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      durageYear: this.props.housingPlanData.housingPlanDurage,
      startYear: this.props.housingPlanData.housingPlanStart,

      durageMessage: '',
      showDurageMessage: false,
      startYearMessage: '',
      showStartYearMessage: false
    }

    this.handleCorrection = this.handleCorrection.bind(this);
    this.handleProductChangeByYear = this.handleProductChangeByYear.bind(this);

    this.handleAbboDurage = this.handleAbboDurage.bind(this);
    this.handleAbboDurageApply = this.handleAbboDurageApply.bind(this);
    this.handleAbboStartYear = this.handleAbboStartYear.bind(this);
    this.handleAbboStartYearApply = this.handleAbboStartYearApply.bind(this);
  }

  addCorrection(amount, year) {
    const housingPlanYear = this.props.housingPlanData.housingPlanData.find(item => item.year === year);
    const currentCorrection = housingPlanYear.correctionPrice === "" ? 0 : Number(housingPlanYear.correctionPrice);

    this.handleCorrection(amount + currentCorrection, year);
  }

  handleCorrection(amount, year) {
    HousingPlanOverviewAction.handlePriceCorrection(year, this.props.housingPlanData.housingPlanData, amount);
    this.props.dispatch(HousingPlanAction.storeHousingPlanData(this.props.housingPlanData.housingPlanData));
  }

  handleProductChangeByYear(event, year) {
    var productAbb = event.target.value;
    var product = this.props.products.find((e) => e.abbreviation === productAbb);
    var yearPlanData = this.props.housingPlanData.housingPlanData.filter((e) => e.year === parseInt(year));

    HousingPlanCalculationAction.changingProductsByHouseYear(yearPlanData, product);
    HousingPlanCalculationAction.calculatePrices(this.props.housingPlanData.housingPlanData);
    this.props.dispatch(HousingPlanAction.storeHousingPlanData(this.props.housingPlanData.housingPlanData));
  }

  handleAbboDurage(event) {
    var durage = parseInt(event.target.value);
    if (!isNaN(durage)){
      this.setState({
        durageYear: durage
      });
    }
  }

  handleAbboDurageApply() {
    if (this.state.durageYear >= 5 && this.state.durageYear <= 30) {
      this.props.dispatch(HousingPlanCalculationAction.changeAbboDurage(this.state.durageYear));

      this.setState({
        durageMessage: 'Bestek is veranderd naar ' + this.state.durageYear + ' jaren',
        showDurageMessage: true,
        durageYear: this.state.durageYear
      });
    } else {
      this.setState({
        durageMessage: 'LET OP: Minimum jaren is 5 en maximum aantal jaren is 30. Pas dit aan.',
        showDurageMessage: true
      });
    }
  }

  handleAbboStartYear(event) {
    let startYear = parseInt(event.target.value);
    if (!isNaN(startYear)){
      this.setState({
        startYear: startYear
      });
    }
  }

  handleAbboStartYearApply() {
    if (this.state.startYear >= 2000) {
      this.props.dispatch(HousingPlanCalculationAction.changeAbboStartYear(this.state.startYear));

      this.setState({
        startYearMessage: 'Startjaar is veranderd naar ' + this.state.startYear,
        showStartYearMessage: true,
        startYear: this.state.startYear
      });        
    } else {
      this.setState({
        startYearMessage: 'LET OP: Het startjaar moet na 2000 zijn.',
        showStartYearMessage: true
      });
    }
  }

  render() {
    var totalPrice = HousingPlanOverviewAction.getTotalPrice(this.props.housingPlanData.housingPlanData);

    return (
      <div className="housingplan-inner">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Jaartal</TableCell>
              <TableCell>Basis</TableCell>
              <TableCell>Correctie</TableCell>
              <TableCell>Totaal</TableCell>
              <TableCell>Wijzig product</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              this.props.housingPlanData.housingPlanData.map((item, i) => (
                <TableRow key={i}>
                  <TableCell className="year-cell">
                    {item.year}
                  </TableCell>
                  <TableCell className="total-price-cell">
                    &#8364; {item.yearTotalPrice.toFixed(2)}
                  </TableCell>
                  <TableCell className="correction-cell">
                    <Grid container>
                      <Grid item xs={8}>
                        <TextField
                          id="correction"
                          type="number"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          margin="normal"
                          variant="outlined"
                          value={item.correctionPrice}
                          onChange={e => this.handleCorrection(e.target.value, item.year)}
                        />                        
                      </Grid>
                      <Grid item xs={4} style={{ paddingLeft: '10px' }}>
                        <Button id={item.year} size="large" className="button-min housing-plan-add-bttn" onClick={(e) => this.addCorrection(50, item.year)}> + &#8364; 50,- </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell className="total-price-cell">
                    &#8364; { item.yearTotalPriceWithCorrection.toFixed(2) }
                  </TableCell>
                  <TableCell>
                    <Select
                      input={
                        <OutlinedInput
                          name="product"
                          id="outlined-age-simple"
                          value=""
                          labelWidth={150}
                        />
                      }
                      onChange={(val) => this.handleProductChangeByYear(val, item.year)}
                    >
                      <MenuItem key="-1" value="">
                        <em>None</em>
                      </MenuItem>
                      {
                        this.props.products.map((product, i) => (
                          <MenuItem key={i} value={product.abbreviation}>{product.abbreviation}</MenuItem>
                        ))
                      }
                    </Select>
                  </TableCell>
                </TableRow>
              ))
            }
            <TableRow>
              <TableCell colSpan={2}></TableCell>
              <TableCell className="total-price">Totaal</TableCell>
              <TableCell className="total-price">&#8364; {totalPrice.toFixed(2)}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <p className="title-abbo-startyearchange"> Startjaar wijzigen </p>
        <Grid container justify="flex-end" className="abbo-startyear">
          <Grid item xs={3}>
            <input type="number" className="form-control abbo-startyear-txt" name="abboStartYear" placeholder={this.props.housingPlanData.housingPlanStart} value={this.state.startYear} onChange={(e) => this.handleAbboStartYear(e)} autoComplete="off" />
          </Grid>
          <Grid item xs={1}>
            <Button size="large" className="button-min housing-plan-add-bttn abbo-startyear-button" onClick={(e) => this.handleAbboStartYearApply(e)}> Wijzig </Button>
          </Grid>
          <Grid item xs={8}>
            {
              this.state.showStartYearMessage ? <div className="abbo-startyear-message"> {this.state.startYearMessage} </div> : <div> </div>
            }
          </Grid>
        </Grid>

        <p className="title-abbo-durationchange"> Abonnementsduur wijzigen </p>
        <Grid container justify="flex-end" className="abbo-duration">
          <Grid item xs={3}>
            <input type="number" className="form-control abbo-duration-txt" name="abboDuration" placeholder={this.props.totalYears} value={this.state.durageYear} onChange={(e) => this.handleAbboDurage(e)} autoComplete="off" />
          </Grid>
          <Grid item xs={1}>
            <Button size="large" className="button-min housing-plan-add-bttn abbo-duration-button" onClick={(e) => this.handleAbboDurageApply(e)}> Wijzig </Button>
          </Grid>
          <Grid item xs={8}>
            {
              this.state.showDurageMessage ? <div className="abbo-durage-message"> {this.state.durageMessage} </div> : <div> </div>
            }
          </Grid>
        </Grid>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    products: state.products.products,
    selectedHousingData: state.housingTypeData,
    housingPlanData: state.housingPlanData
  }
}

const connected = connect(mapStateToProps)(OverviewHousingPlanComponent);
export { connected as OverviewHousingPlanComponent };



