import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';

import * as Login from './pages/login/login.Reducer';
import * as HousingType from './pages/lead/housingType/housingType.Reducer';
import * as Product from './pages/lead/housingType/product/product.Reducer';
import * as HousingFace from './pages/lead/housingFace/housingFace.Reducer';
import * as LeadCalculation from './pages/lead/leadCalculation/leadCalculation.Reducer';
import * as LeadConfirmReducer from './pages/lead/leadConfirm/leadConfirm.Reducer';
import * as LeadCustomer from './pages/lead/leadCustomer/leadCustomer.Reducer';
import * as HousingPlan from './pages/lead/leadHousingPlan/leadHousingPlan.Reducer';
import * as FinishPlan from './pages/lead/leadFinish/leadFinish.Reducer';
import * as LeadOverviewReducer from './pages/lead/leadOverview/leadOverview.Reducer';
import { photoPanelReducer } from './pages/lead/leadPhoto/photoPanel/photoPanel.Reducer';

import { headerConstants } from './components/layout/header/header.Constants';

export default function configureStore(history, persistedState) {
  const appReducer = combineReducers({
    login: Login.loginReducer,
    housingTypes: HousingType.housingTypeReducer,
    products: Product.productReducer,
    leadCalculation: LeadCalculation.leadCalculationReducer,
    leadCustomers: LeadCustomer.LeadCustomerReducer,
    selectedImplementation: HousingFace.housingFaceReducer,
    housingPlanData: HousingPlan.housingPlanReducer,
    housingPlanFinish: FinishPlan.finishReducer,
    leadOverview: LeadOverviewReducer.leadOverviewReducer,
    leadConfirmation: LeadConfirmReducer.leadConfirmReducer,
    leadPhoto: photoPanelReducer
  });

  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    }) : compose;

  let enhancer = composeEnhancers(
    applyMiddleware(...middleware)
  );

  const rootReducer = (state,action) => { 
    if (action.type === headerConstants.LOGOUT) {
      return appReducer(undefined, action);
    }
    else if (action.type === headerConstants.HARD_RESET) {
      localStorage.removeItem("reduxState");
      return appReducer({login:state.login }, action);
    }
    else if (action.type === headerConstants.SOFT_RESET) {
      localStorage.removeItem("reduxState");
      return appReducer({login:state.login, leadCustomers: state.leadCustomers }, action);
    }
    return appReducer(state,action);
  }

  return createStore(
    rootReducer,
    persistedState,
    enhancer
  );
}