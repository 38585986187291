import React, { Component } from 'react';
import { history } from 'helpers/history';
import './title.Style.css';

export class TitleComponent extends Component {
  render() {
    let onClick;
    if(!this.props.onClick){
      onClick = () => history.goBack();
    } else {
      onClick = this.props.onClick;
    }
    return (
      <div className="title-wrapper"> 
      {
        this.props.hideBackButton ? <div> </div> :  <div className="title-container"> <p onClick={onClick} className="back-buttn"> <img src={require('../../../images/previous.svg')} alt="back" />   Terug </p> </div> 
      }
         <div className="title-container"> <span className="title-center">{this.props.title}  </span> </div> 
        </div>
    )
  }
}
