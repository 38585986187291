import React, { Component } from 'react';
import { connect } from 'react-redux';
import { history } from 'helpers/history';
import { TitleComponent, HeaderComponent } from 'components';
import { Button, Paper, Checkbox, Input } from '@material-ui/core';
import PhotoPanel from '../leadPhoto/photoPanel/photoPanel.Component';

import { accessibilityConfirmed, addRepair, deleteRepair, updateRepair } from './leadConfirm.Action';
import './leadConfirm.Style.css';

class LeadConfirmComponent extends Component {

  handleConfirmAccessibility(checked) {
    const { dispatch } = this.props;
    dispatch(accessibilityConfirmed(checked));
  }

  handleNextButton() {
    history.push('/createLead/calculation');
  }

  handleAddRepair() {
    const { dispatch } = this.props;
    dispatch(addRepair());
  }

  handleDeleteRepair(index) {
    const { dispatch } = this.props;
    dispatch(deleteRepair(index));
  }

  handleUpdateRepair(index, text) {
    const { dispatch } = this.props;
    dispatch(updateRepair(index, text));
  }

  render() {
    const { accessibilityConfirmed, repairs } = this.props;

    return (
      <div className="page-wrapper">
        <div className="page-header">
          <HeaderComponent />
        </div>
        <div className="page-content">
          <div className="page-title">
            <TitleComponent title="Controlevragen" />
          </div>
          <div className="page-inner-content-default">
            <div className="confirm-container">
              <Paper className="confirm-paper">
                <p>Plaats hier foto's van slechte delen of delen met houtrot.</p>
                <PhotoPanel category="Houtrot" />
                <PhotoPanel category="Slechte delen" />
                <p>Nog uit te voeren herstelwerkzaamheden</p>
                <div id="dynamicInput">
                  {repairs && repairs.map((repair, index) =>
                    <div key={`herstel-div-${index}`} className="repair-input-container">
                      <Input key={`herstel-input-${index}`} className="form-control repair-input" type="text" value={repair} placeholder={`Herstelwerkzaamheden #${index + 1}`} onChange={e => this.handleUpdateRepair(index, e.target.value)} />
                      <Button className="delete-repair-button" onClick={() => this.handleDeleteRepair(index)}> </Button>
                    </div>
                  )}
                </div>
                <Button className="add-repair-button" onClick={() => this.handleAddRepair()}>+</Button>
                <div><Checkbox checked={accessibilityConfirmed} onChange={(e, checked) => this.handleConfirmAccessibility(checked)} />De bereikbaarheid van de te schilderen onderdelen is naar waarheid ingevoerd.</div>
              </Paper>
            </div>
          </div>
        </div>
        <div className="page-footer">
          <div className="page-footer-inner">
            <span className={accessibilityConfirmed ? "" : "hidden"} onClick={() => this.handleNextButton()}> <span className="next-button-text">Volgende</span> <img src={require('../../../images/next.svg')} className="next-button" alt="next" /> </span>
          </div>
        </div> 
      </div>
    )
  }
}

function mapStateToProps(state) {  
  return {
    accessibilityConfirmed: state.leadConfirmation.accessibilityConfirmed,
    photos: state.leadPhoto.photos,
    repairs: state.leadConfirmation.repairs
  }
}

export default connect(mapStateToProps)(LeadConfirmComponent);
